import React, { createContext, useState } from 'react'

const ListaVerificacionContext = createContext(null)

function ListaVerificacionProvider(props){
    const [cycle, setCycle] = useState(null)
    const [claveDocente, setClaveDocente] = useState(null)
    const [docente, setDocente] = useState(null)
    const [level, setLevel] = useState(null)
    const [claveMateria, setClaveMateria] = useState(null)

    function setState(newCycle, newDocente, newLevel, newClave, newClaveMateria){
        setCycle(newCycle)
        setDocente(newDocente)
        setLevel(newLevel)
        setClaveDocente(newClave)
        setClaveMateria(newClaveMateria)
    }

    const value = {
        state: {
            cycle,
            docente,
            level,
            claveDocente,
            claveMateria
        },
        actions: {
            setState
        }
    }

    return (
        <ListaVerificacionContext.Provider value={value}>
            {props.children}
        </ListaVerificacionContext.Provider>
    )

}

export default ListaVerificacionContext
export {ListaVerificacionProvider}