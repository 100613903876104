import React from 'react'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import './index.css'

export default function LargeModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      style={{ zIndex: 9999999 }}
      disableBackdropClick
    >
      <div className='LargeModal' style={{ height: props.height }}>
        <CloseIcon onClick={props.handleClose} />
        <h3> {props.title} </h3>
        <div className='LargeModal-container' style={{ position: 'relative', height: 'calc(100% - 70px)' }}>
          {props.loading
            ? <div style={{ textAlign: 'center', paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div>

            : <>
              {props.children}
              {props.noFooter ? null
                : <div className='LargeModal-footer'>
                  <div>
                    {!props.descarga ? null
                      : <button
                        className='btn-download'
                        onClick={props.descarga}
                      > Descargar formato
                      </button>
                    }

                    {!props.subir ? null
                      : <>
                        <input
                          id='input-examinar'
                          style={{ display: 'none' }}
                          type='file'
                          onChange={(e) => {
                            props.subir(e)
                            e.target.value = ''
                          }}
                        />
                        <button
                          className='btn-upload'
                          onClick={() => { document.getElementById('input-examinar').click() }}
                        > Carga masiva
                        </button>
                      </>
                    }
                  </div>

                  <div>
                    <button
                      className='btn-secondary'
                      onClick={props.handleClose}
                    > Cerrar
                    </button>

                    {!props.submittable ? null
                      : <button
                        className='btn-black'
                        disabled={props.disabled}
                        style={props.disabled ? { opacity: '.25' } : null}
                        onClick={props.handleSubmit}
                      > Guardar
                      </button>
                    }
                  </div>

                  <div>
                    {!props.handleStatus ? null
                      : <button
                        className={props.active ? 'btn-danger' : 'btn-success'}
                        onClick={props.handleStatus}
                      > {props.active ? 'Desactivar' : 'Activar'}
                      </button>
                    }

                    {!props.handleDelete ? null
                      : <button
                        className={'btn-danger'}
                        onClick={props.handleDelete}
                      > Eliminar
                      </button>
                    }
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </Modal>
  )
} 