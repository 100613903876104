import React from "react";

const HistorialIcon = props => (
  <svg viewBox="0 0 23.87 30" {...props}>
    <defs>
      <style>{".HistorialIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="HistorialIcon_svg__Layer_2" data-name="Layer 2">
      <g id="HistorialIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="HistorialIcon_svg__cls-1"
          d="M22.76 0H1.11A1.11 1.11 0 000 1.11v27.78A1.11 1.11 0 001.11 30h21.65a1.11 1.11 0 001.11-1.11V1.11A1.11 1.11 0 0022.76 0zm-1.11 27.78H2.22V2.22h19.43z"
        />
        <path
          className="HistorialIcon_svg__cls-1"
          d="M18.25 4.47H5.58a1.11 1.11 0 00-1.11 1.11v5.73a1.11 1.11 0 001.11 1.11h12.67a1.11 1.11 0 001.11-1.11V5.58a1.11 1.11 0 00-1.11-1.11zm-1.11 5.73H6.69V6.69h10.45zM17.84 15.11H5.58a1.11 1.11 0 100 2.22h12.26a1.11 1.11 0 000-2.22zM18.29 19.21H5.58a1.11 1.11 0 100 2.22h12.71a1.11 1.11 0 100-2.22zM18.29 23.31H5.58a1.11 1.11 0 000 2.22h12.71a1.11 1.11 0 000-2.22z"
        />
      </g>
    </g>
  </svg>
);

export default HistorialIcon;
