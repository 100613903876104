let URL_BASE = "https://sjcpme7f3d.execute-api.us-west-2.amazonaws.com/production"
let URL_DUMMY = "https://x1xtntd9l8.execute-api.us-west-2.amazonaws.com/dev"
let URL_SESSION_OPENID = "https://e8klvcfqt0.execute-api.us-east-1.amazonaws.com/test/sesion"
export const version = "0.0.1"

export async function getRequest(
  ws,
  signal = new AbortController().signal,
  dummy = false,
  session = false
) {
  let token = localStorage.getItem("token_tv");
  let url;
  if (dummy) {
    url = URL_DUMMY;
  } else if (session) {
    url = URL_SESSION_OPENID;
  } else {
    url = URL_BASE;
  }
  let response = await fetch(url + ws, {
    headers: { tk: token },
    signal: signal,
  });
  let data = await response.json();
  if (data.m === "token no existe o expirado") {
    localStorage.removeItem("token_tv");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  } else {
    return data;
  }
}

export async function postRequest(
  ws,
  json = {},
  signal = new AbortController().signal,
  dummy = false
) {
  let token = localStorage.getItem("token_tv");
  let url = dummy ? URL_DUMMY : URL_BASE;
  let response = await fetch(url + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      tk: token,
    },
    body: JSON.stringify(json),
    signal: signal,
  });
  let data = await response.json();
  if (data.m === "token no existe o expirado") {
    localStorage.removeItem("token_tv");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  } else {
    return data;
  }
}

export async function postLogin(ws, json = {}) {
  let response = await fetch(URL_BASE + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });
  let data = await response.json();
  if (data.m === "token no existe o expirado") {
    localStorage.removeItem("token_tv");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  } else {
    return data;
  }
}

export async function putRequest(url, file) {
  let token = localStorage.getItem("token_tv");
  await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "image/png",
      "Content-Disposition": "inline",
      tk: token,
    },
  });
  return { status: "OK" };
}
