import React, {useState} from 'react';
import SearchIcon from '../../../images/SearchIcon';
import './style.css';

export default function SearchInput(props){
    const [searchIcon, setSearchIcon] = useState(true);

    return(
        <>
            {searchIcon ? <SearchIcon className='SearchInputIcon' /> : null}
            <input onChange={e => {
                props.search(e.target.value);
                if(props.onChange){
                    props.onChange(e.target.value);
                }
            }} type='search' placeholder='Buscar' className='SearchInput' onFocus={() => {
                setSearchIcon(false)
            }} onBlur={(e) => {
                if(e.target.value === '') setSearchIcon(true)
            }} />
        </>
    )
}