import React, { useState, useEffect, useContext } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import SearchInput from '../../Utils/Visuales/SearchInput'
import MasivaButton from '../../Utils/Botones/MasivaButton'
import DataTable from '../../Utils/DataTables'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'
import Select from 'react-select'
import { useSnackbar } from 'notistack'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'
import CursoContext from '../../Utils/Providers/CursoProvider'
import NivelesContext from '../../Utils/Providers/NivelesProvider'
import BlackButton from '../../Utils/Botones/BlackButton'
import { STYLES } from '../../Utils/variables'

function ReportesAsignacion(props) {
  const context = useContext(CursoContext)

  const headers = [
    { col: 0, name: 'Clave del Criterio' },
    { col: 1, name: 'Descripción del Criterio' },
    { col: 2, name: 'Cumplimiento' }
  ]
  const [dataFinal, setDataFinal] = useState([])
  const [dataReal, setDataReal] = useState([])
  const [loading, setLoading] = useState(true)
  const [levels, setLevels] = useState(useContext(NivelesContext).state.levels)
  const [level, setLevel] = useState('')
  const [weeks, setWeeks] = useState([])
  const [week, setWeek] = useState('')
  const [year, setYear] = useState(context.state.year ? context.state.year : new Date())
  const [cycles, setCycles] = useState([
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' }
  ])
  const [cycle, setCycle] = useState(context.state.cycle ? {
    value: context.state.cycle,
    label: context.state.cycle
  } : {
    value: '01',
    label: '01'
  })
  const [docentes, setDocentes] = useState([])
  const [docente, setDocente] = useState('')
  const [cursos, setCursos] = useState([])
  const [curso, setCurso] = useState('')
  const [firstTime, setFirstTime] = useState(0)
  const [btnEnable, setBtnEnable] = useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (context.state.level) {
      let index = levels.findIndex(elem => elem.value === context.state.level)
      setLevel(levels[index])
    } else {
      setLevel(levels[0])
    }
  }, [])

  useEffect(() => {
    if (level !== '') {
      let weeksArray = []
      for (let i = 1; i <= level.semanas; i++) {
        weeksArray.push({
          label: i,
          value: i
        })
      }
      setWeeks(weeksArray)
      if (context.state.week) {
        setWeek({
          label: context.state.week,
          value: context.state.week
        })
      } else {
        setWeek({
          label: 1,
          value: 1
        })
      }
    }
  }, [level])

  useEffect(() => {
    if (level !== '') {
      const pageController = new AbortController()
      const pageSignal = pageController.signal

      async function fetchData() {
        let json = {
          year: moment(year).format('YY'),
          ciclo: cycle.value,
          nivel: level.value
        }
        setDocentes([])
        setDocente('')
        setLoading(true)
        setBtnEnable(true)
        let response = await postRequest('/reporte/lista/verificacion', json, pageSignal)
        if (response.s === 'OK') {

          let docentesArray = []
          if (response.d) {
            for (let i = 0; i < response.d.length; i++) {
              let index = docentesArray.findIndex(elem => elem.value === response.d[i].clave_docente)
              if (index === -1) {
                docentesArray.push({
                  value: response.d[i].clave_docente,
                  label: response.d[i].nombre_docente,
                  cursos: [
                    {
                      value: response.d[i].clave_curso,
                      label: `${response.d[i].clave_curso} ${response.d[i].nombre_curso}`
                    }
                  ]
                })
              } else {
                docentesArray[index].cursos.push({
                  value: response.d[i].clave_curso,
                  label: `${response.d[i].clave_curso} ${response.d[i].nombre_curso}`
                })
              }
            }
            setDocentes(docentesArray)
          } else {
            enqueueSnackbar(response.m, STYLES.failure)
          }

          if (context.state.docente) {
            let indexDocente = docentesArray.findIndex(e => e.value === context.state.docente)
            setDocente(docentesArray[indexDocente])
          } else {
            setDocente(docentesArray[0])
          }
          if (docentesArray.length === 0) {
            setBtnEnable(true)
          } else {
            setBtnEnable(false)
          }
          setLoading(false)
        }
      }

      fetchData()
    }
  }, [year, cycle, level])

  useEffect(() => {
    if (docentes.length > 0) {
      if (context.state.docente) {
        setCursos(docente.cursos)
        let indexCurso = docente.cursos.findIndex(e => e.value === context.state.curso)
        setCurso(docente.cursos[indexCurso])
      } else {
        setCursos(docente.cursos)
        setCurso(docente.cursos[0])
      }
    } else {
      setCursos([])
      setCurso('')
    }
  }, [docente])

  useEffect(() => {
    if (curso !== '') {
      if (firstTime === 0) {
        fetchData()
        setFirstTime(1)
        context.actions.setState(null, null, null, null, null, null)
      }
    }
  }, [curso])

  function fetchData() {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    async function fetchData2() {
      try {
        setLoading(true)
        let json = {}
        if (context.state.cycle) {
          json = {
            ciclo: context.state.cycle,
            year: moment(context.state.year).format('YY'),
            nivel: context.state.level,
            semana: context.state.week,
            clave: context.state.curso
          }
        } else {
          json = {
            ciclo: cycle.value,
            year: moment(year).format('YY'),
            nivel: level.value,
            semana: week.value,
            clave: curso.value
          }
        }
        let response = await postRequest('/reporte/criterio', json, pageSignal)
        if (response.s === 'OK') {
          let data = []
          if (response.d) {
            response.d.forEach(row => {
              data.push([
                row.clave,
                row.descripcion,
                row.porcentaje_alcance === '100.00' ? 'Cumplió' : (row.porcentaje_alcance === '0.00' ? 'No cumplió' : 'NA')
              ])
            })
          } else {
            enqueueSnackbar(response.m, STYLES.failure)
          }
          setLoading(false)
          setDataFinal(data)
          setDataReal(data)
        } else {
          enqueueSnackbar(response.m, STYLES.failure)
        }
      } catch (error) {

      }
    }
    fetchData2()
    return function cleanup() {
      pageController.abort()
    }
  }

  function search(text) {
    text = text.toLowerCase()
    let result = dataReal.filter(row => {
      if (row[0].toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      else if (row[2].toLowerCase().includes(text)) return true
      return false
    })
    setDataFinal(result)
  }

  function downloadReport() {
    let dataDownload = []
    dataDownload.push({
      DOCENTE: 'CURSO',
      [docente.label]: curso.label,
      '': ''
    })
    dataDownload.push({
      DOCENTE: 'SEMANA',
      [docente.label]: week.value,
      '': ''
    })
    dataDownload.push({
      DOCENTE: 'Clave',
      [docente.label]: 'Descripcion',
      '': 'Alcance'
    })
    console.log(curso)
    console.log(week)
    dataReal.forEach(row => {
      dataDownload.push({
        DOCENTE: row[0],
        [docente.label]: row[1],
        '': row[2]
      })
    })
    const fileType = 'application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8'
    const ws = XLSX.utils.json_to_sheet(dataDownload)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    let today = moment().format('YYYY/MM/DD')
    FileSaver.saveAs(data, `Reporte de Cursos ${today}.xlsx`)
  }

  return (
    <Contenedor title='Reporte de Grupos'>
      <div className='opciones-bar opciones-bar-reporte'>
        <div className='tab-filtros' style={{ display: 'block' }}>
          <div>
            <MasivaButton onClick={downloadReport}>Descargar</MasivaButton>
          </div>
          <div>
            <p>Año:</p>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <DatePicker views={['year']} value={year} onChange={setYear} className='date-year' />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <p>Ciclo:</p>
            <Select
              options={cycles}
              className='select-weeks'
              classNamePrefix='select-search'
              value={cycle}
              onChange={setCycle}
            />
          </div>
          <div>
            <p>Nivel:</p>
            <Select
              options={levels}
              className='select-weeks'
              classNamePrefix='select-search'
              value={level}
              onChange={setLevel}
            />
          </div>
          <div>
            <p>Semana:</p>
            <Select
              options={weeks}
              className='select-weeks'
              classNamePrefix='select-search'
              value={week}
              onChange={setWeek}
            />
          </div>
          <div>
            <p>Docente:</p>
            <Select
              options={docentes}
              className='select-docente-largo'
              classNamePrefix='select-search'
              value={docente}
              onChange={setDocente}
              isDisabled={docentes.length === 0}
            />
          </div>
          <div>
            <p>Curso:</p>
            <Select
              options={cursos}
              className='select-docente-largo'
              classNamePrefix='select-search'
              value={curso}
              onChange={setCurso}
              isDisabled={docentes.length === 0}
            />
          </div>
          <div>
            <BlackButton
              style={{ marginLeft: '20px' }}
              onClick={() => fetchData()}
              disabled={btnEnable}
            > Buscar
            </BlackButton>
          </div>
          <div style={{ float: 'right', paddingTop: '15px', marginLeft: 5, position: 'relative' }}>
            <SearchInput search={search} />
          </div>
        </div>
      </div>
      <div style={{ height: 'calc(100% - 120px)' }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          paginate
        />
      </div>
    </Contenedor>
  )
}

export default React.memo(ReportesAsignacion);