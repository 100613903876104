import React, { createContext, useState, useEffect } from 'react';

const NivelesContext = createContext(null);

function NivelesProvider(props){
    const [levels, setLevels] = useState([]);

    function setNiveles(newNiveles){
        setLevels(newNiveles);
    }

    const value = {
        state: {
            levels
        },
        actions: {
            setNiveles
        }
    }

    return (
        <NivelesContext.Provider value={value}>
            {props.children}
        </NivelesContext.Provider>
    )
}

export default NivelesContext;
export { NivelesProvider };