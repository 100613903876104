import React from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function RegenerarModal(props){
    return(
        <Modal open={props.open} style={{zIndex: 9999999}} disableBackdropClick>
            <div className='NewModal'>
                <h3>Regenerar</h3>
                <div className='NewModal-container' style={{height: props.height, position: 'relative'}}>
                    {
                        props.children
                    }
                </div>
            </div>
        </Modal>
    )
}