import React, { createContext, useState } from 'react';

const CursoContext = createContext(null);

function CursoProvider(props){
    const [year, setYear] = useState(null);
    const [cycle, setCycle] = useState(null);
    const [level, setLevel] = useState(null);
    const [week, setWeek] = useState(null);
    const [docente, setDocente] = useState(null);
    const [curso, setCurso] = useState(null);

    function setState(newYear, newCycle, newLevel, newWeek, newDocente, newCurso){
        setYear(newYear);
        setCycle(newCycle);
        setLevel(newLevel);
        setWeek(newWeek);
        setDocente(newDocente);
        setCurso(newCurso);
    }

    const value = {
        state: {
            year,
            cycle,
            level,
            week,
            docente,
            curso
        },
        actions: {
            setState
        }
    }

    return (
        <CursoContext.Provider value={value}>
            {props.children}
        </CursoContext.Provider>
    )
}

export default CursoContext;
export {CursoProvider}