import React, { useState, useEffect, useRef } from 'react';
import Contenedor from '../../Utils/Visuales/Contenedor';
import MasivaButton from '../../Utils/Botones/MasivaButton';
import SearchInput from '../../Utils/Visuales/SearchInput';
import DataTable from '../../Utils/DataTables';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import EditButton from '../../Utils/Botones/EditButton';
import LargeModal from '../../Utils/Visuales/LargeModal';
import NewModal from '../../Utils/Visuales/NewModal';
import ForwardIcon from '@material-ui/icons/Forward';
import MasivaModal from '../../Utils/Visuales/MasivaModal';
import BlackButton from '../../Utils/Botones/BlackButton';
import { useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import './style.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'

export default function Coordinadores(){
    const headers = [
        {
            name: 'Clave',
            col: 0
        },
        {
            name: 'Nombre',
            col: 1
        },
        {
            name: 'Rol',
            col: 2
        },
        {
            name: 'Acción',
            col: 3
        }
    ];
    const [dataOriginal, setDataOriginal] = useState([]);
    const [dataFinal, setDataFinal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Edit Modal
    const modalHeaders = [
        {
            name: 'Clave',
            col: 0
        },
        {
            name: 'Nombre',
            col: 1
        }
    ];
    const [editModal, setEditModal] = useState(0);
    const [editModalLoading, setEditModalLoading] = useState(false);
    const [modalCoord, setModalCoord] = useState('');
    const [modalRol, setModalRol] = useState('');
    const [docentes, setDocentes] = useState([]);
    const [docentesAsignados, setDocentesAsignados] = useState([]);
    const [docentesFinal, setDocentesFinal] = useState([]);
    const [docentesAsignadosFinal, setDocentesAsignadosFinal] = useState([]);
    const [loadingDocentes, setLoadingDocentes] = useState(false);
    const [loadingDocentesAsignados, setLoadingDocentesAsignados] = useState(false);
    const [refreshModal, setRefreshModal] = useState(false);
    const [idCoordinador, setIdCoordinador] = useState('');

    // Modal carga masiva
    const [modalMasiva, setModalMasiva] = useState(false);
    const [modalMasivaLoading, setModalMasivaLoading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const newFile = useRef(null);
    const masivaHeaders = [
        {
            name: 'Clave',
            col: 0
        },
        {
            name: 'Coordinador',
            col: 1
        },
        {
            name: 'Total docentes',
            col: 2
        },
        {
            name: 'Estatus',
            col: 3
        }
    ];
    const [tableMasiva, setTableMasiva] = useState([]);
    const [dataUpload, setDataUpload] = useState([]);
    const [usuarios,setUsuarios]=useState([]);

    //Descarga masiva
    const [descargaModal,setDescargaModal]=useState(false)
    const [coordinadores,setCoordinadores]=useState([])

    //Controladores snackbar
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMsg, setSnackMsg] = useState('')
    const [snackType, setSnackType] = useState('')

    useEffect(() => {
        const pageController = new AbortController()
        const pageSignal = pageController.signal
        async function fetchData(){
            try {
                setLoading(true);
                let response = await getRequest('/usuario', pageSignal);
                if(response.s === 'OK'){
                    let data = [];
                    let dataCompleta=[];
                    for(let i = 0; i < response.d.length; i++){
                        if (!response.d[i].rol.includes('administrador')){
                            let row = [response.d[i].clave, response.d[i].nombre, response.d[i].rol.join(', '), <EditButton onClick={() => openEditModal(response.d[i].id, response.d[i].clave, response.d[i].nombre, response.d[i].rol.join(', '))}>Detalle</EditButton>];
                            data.push(row);
                        }
                    }
                    setCoordinadores(dataCompleta);
                    setDataOriginal(data);
                    setDataFinal(data);
                    setLoading(false);
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            } catch (error) {

            }
        };
        fetchData();
        return function cleanup(){
            pageController.abort()
        }
    }, [refresh])

    async function deleteRelacion(id_coordinador, id_docente){
        setLoadingDocentes(true);
        setLoadingDocentesAsignados(true);
        let response = await postRequest('/coordinador/docente/desvincular', {
            id_coordinador,
            id_docente
        });
        if(response.s === 'OK'){
            setRefreshModal(!refreshModal);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    async function nuevaRelacion(id_coordinador, id_docente){
        setLoadingDocentes(true);
        setLoadingDocentesAsignados(true);
        let response = await postRequest('/coordinador/docente/vincular', {
            id_coordinador,
            id_docente
        });
        if(response.s === 'OK'){
            setRefreshModal(!refreshModal);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    useEffect(() => {
        async function fetchData(){
            setLoadingDocentes(true);
            setLoadingDocentesAsignados(true);
            let responseDocentes = await getRequest('/docente');
            if(responseDocentes.s === 'OK'){
                let responseRelacion = await getRequest(`/coordinador/docente?id_coordinador=${idCoordinador}`);
                if(responseRelacion.s === 'OK'){
                    let idsAsignados = [];
                    if(responseRelacion.d){
                        idsAsignados = responseRelacion.d.map(row => (
                            row.id
                        ));
                    }
                    let docentesNoAsignados = responseDocentes.d.filter(row => {
                        return idsAsignados.indexOf(row.id) === -1
                    });
                    docentesNoAsignados = docentesNoAsignados.map(row => ([
                        row.clave,
                        row.nombre,
                        <div className='arrow-box'>
                            <ForwardIcon className='forward-btn' onClick={() => nuevaRelacion(idCoordinador, row.id)} />
                        </div>
                    ]))
                    setDocentes(docentesNoAsignados);
                    setDocentesFinal(docentesNoAsignados);
                    let docsAsignados = [];
                    if(responseRelacion.d){
                        docsAsignados = responseRelacion.d.map(row => ([
                            row.clave,
                            row.nombre
                        ]))
                    }
                    setDocentesAsignados(docsAsignados);
                    setDocentesAsignadosFinal(docsAsignados);
                    setLoadingDocentes(false);
                    setLoadingDocentesAsignados(false);
                }else{
                    setEditModal(false)
                    setSnackMsg(responseRelacion.m)
                    setSnackType('error')
                    setSnackOpen(true)    
                }
            }else{
                setEditModal(false)
                setSnackMsg(responseDocentes.m)
                setSnackType('error')
                setSnackOpen(true)
            }
        }
        if(editModal){
            fetchData();
        }
    }, [editModal,refreshModal])

    async function openEditModal(id, clave, nombre, rol){
        setEditModal(true);
        setIdCoordinador(id);
        setModalCoord(`${clave} - ${nombre}`);
        setModalRol(rol);
        setRefreshModal(!refreshModal);
    }

    function search(text){
        text = text.toLowerCase();
        let result = dataOriginal.filter(row => {
            if(row[0].toString().toLowerCase().includes(text)) return true;
            else if(row[1].toLowerCase().includes(text)) return true;
            else if(row[2].toLowerCase().includes(text)) return true;
            else return false;
        });
        setDataFinal(result);
    }

    function searchDocentes(text){
        text = text.toLowerCase();
        let result = docentes.filter(row => {
            if(row[0].toString().toLowerCase().includes(text)) return true;
            else if(row[1].toLowerCase().includes(text)) return true;
            else return false;
        });
        setDocentesFinal(result);
    }

    function searchDocentesAsignados(text){
        text = text.toLowerCase();
        let result = docentesAsignados.filter(row => {
            if(row[0].toString().toLowerCase().includes(text)) return true;
            else if(row[1].toLowerCase().includes(text)) return true;
            else return false;
        });
        setDocentesAsignadosFinal(result);
    }

    function openMasivaModal(){
        setModalMasiva(true);
        setUploaded(false);
    }

    function downloadFormat(){
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet([{
            clave_usuario: '',
            clave_docente: ''
        }]);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Formato Carga Masiva de Coordinadores.xlsx`);
    }

    /*Esta funcion sera recursiva*/
    async function getByCoordinador(){
        let response = await postRequest('/reporte/docente/coordinador')
        if(response.s === 'OK'){
            let dataToDownload = []
            for(let i = 0; i < response.d.length; i++){
                if(response.d[i].docentes.length > 0){
                    response.d[i].docentes.forEach(doc => {
                        let rolCoord = '';
                        if(typeof doc.rol_coordinador === 'object'){
                            rolCoord = doc.rol_coordinador.join(', ');
                        } else {
                            rolCoord = doc.rol_coordinador;
                        }

                        dataToDownload.push({
                            clave_docente: doc.clave,
                            nombre: doc.nombre,
                            rol: rolCoord,
                            clave_coordinador: response.d[i].clave_coordinador,
                            coordinador: response.d[i].nombre_coordinador
                        })
                    })
                } else {
                    let rolCoord = '';
                    if(typeof response.d[i].rol_coordinador === 'object'){
                        rolCoord = response.d[i].rol_coordinador.join(', ');
                    } else {
                        rolCoord = response.d[i].rol_coordinador;
                    }

                    dataToDownload.push({
                        clave_docente: '',
                        nombre: '',
                        rol: rolCoord,
                        clave_coordinador: response.d[i].clave_coordinador,
                        coordinador: response.d[i].nombre_coordinador
                    })
                }
            }
            const fileType = 'application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8'
            const ws = XLSX.utils.json_to_sheet(dataToDownload)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data']}
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], {type: fileType})
            let today = moment().format('YYYY/MM/DD')
            FileSaver.saveAs(data, `Reporte Coordinadores ${today}.xlsx`)
            setDescargaModal(false)
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setDescargaModal(false)
        }
    }


    function descargarTabla(){
        setDescargaModal(true)
        getByCoordinador()
    }

    function uploadFile(e){
        var reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);

        reader.onload = async e => {
            let data = new Uint8Array(reader.result);
            let wb = XLSX.read(data, {type: 'array'});
            let dataJSON = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);

            setModalMasivaLoading(true);
            let docentesResponse = await getRequest('/docente');
            if(docentesResponse.s === 'OK'){
                setUsuarios(docentesResponse.d)
            }




            let usuariosResponse = await getRequest('/usuario');
            if(usuariosResponse.s === 'OK'){
                let dataMasiva = [];
                for(let i = 0; i < dataJSON.length; i++){
                    const index = dataMasiva.findIndex(element => element.clave === dataJSON[i].clave_usuario);
                    if(index !== -1){
                        dataMasiva[index].docentes.push(dataJSON[i].clave_docente);
                    } else {
                        let indexName = usuariosResponse.d.findIndex(element => element.clave === dataJSON[i].clave_usuario);
                        if(indexName !== -1){
                            dataMasiva.push({
                                id: usuariosResponse.d[indexName].id,
                                clave: dataJSON[i].clave_usuario,
                                name: usuariosResponse.d[indexName].nombre,
                                docentes: [dataJSON[i].clave_docente]
                            });
                        } else {
                            enqueueSnackbar(`El usuario de clave ${dataJSON[i].clave_usuario} no existe.`, {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            });
                        }
                    }
                }
                let tablaData = dataMasiva.map(coordinador => (
                    [coordinador.clave, coordinador.name, coordinador.docentes.length, <span id={`estatus-${coordinador.clave}`}></span>]
                ));
                setDataUpload(dataMasiva);
                setTableMasiva(tablaData);
                setUploaded(true);
                setModalMasivaLoading(false);
            } else {
                enqueueSnackbar(usuariosResponse.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }

        }
    }

    async function procesarMasiva(){
        let vinculado=false;
        setModalMasivaLoading(true)
        for(let i = 0; i < dataUpload.length; i++){
            let responseDelete = await postRequest('/coordinador/docente/desvincular', {
                id_coordinador: dataUpload[i].id,
                id_docente: 'all'
            });
            if(responseDelete.s === 'OK'){
                dataUpload.forEach(vinculo=>{
                    vinculo.docentes.forEach(docente=>{
                        usuarios.forEach(usuario=>{
                            if(usuario.clave === docente){
                                vinculado=true;
                                nuevaRelacion(vinculo.id,usuario.id);
                            }
                        })
                        if(!vinculado){
                            enqueueSnackbar(`El usuario de clave ${docente} no existe.`, {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            });
                        }
                        vinculado=false
                    })
                })
            }
        }
        setModalMasivaLoading(false)
    }

    return(
        <Contenedor title='Coordinadores'>
            <div className='opciones-bar'>
                <div style={{float: 'left','display':'none'}}>
                    <MasivaButton onClick={openMasivaModal}>Carga Masiva</MasivaButton>
                </div>
                <div style={{float: 'left'}}>
                    <MasivaButton onClick={descargarTabla}>Descargar</MasivaButton>
                </div>
                <div style={{float: 'right'}}>
                    <SearchInput search={search} />
                </div>
            </div>
            <div style={{height: 'calc(100% - 65px)', marginTop: '10px'}}>
                <DataTable
                    headers={headers}
                    data={dataFinal}
                    loading={loading}
                    centrar={[3,4]}
                    paginate
                />
            </div>
            <NewModal noSaveBtn title='Preparando Descarga' open={descargaModal} handleClose={() => setDescargaModal(false)} loading={editModalLoading} height={300}>
                    <div style={{textAlign: 'center', paddingTop: 50}}>
                        <h2>
                            Descargando reporte de coordinadores.
                        </h2>
                    </div>
                    <div style={{textAlign: 'center', paddingTop: 50}}>
                            <CircularProgress size={50} />
                    </div> :  
            </NewModal>
            <NewModal title='Detalle Coordinador' noSaveBtn open={editModal} handleClose={() => setEditModal(false)} loading={editModalLoading} height={500}>
                <div className='opciones-bar inputs-modal inputs-calendar'>
                    <div style={{float: 'left'}}>
                        <label>Coordinador:</label>
                        <input value={modalCoord} style={{width: '250px'}} disabled />
                        <label>Rol:</label>
                        <input value={modalRol} style={{width: '200px'}} disabled />
                    </div>
                </div>
                <div className='Coordinador-Menu-Table'>
                    <div>
                        <p>Docentes asignados:</p>
                        <div className='Coordinador-Table-Box'>
                            <div className='opciones-box' style={{display: 'inline-block', width: '100%'}}>
                                <div style={{float: 'right'}}>
                                    <SearchInput search={searchDocentesAsignados} />
                                </div>
                            </div>
                            <div style={{marginTop: '5px', height: 'calc(100% - 45px)'}}>
                                <DataTable 
                                    headers={modalHeaders}
                                    data={docentesAsignadosFinal}
                                    loading={loadingDocentesAsignados}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewModal>
            <MasivaModal handleSubmit={procesarMasiva} open={modalMasiva} disabled={!uploaded} handleClose={() => setModalMasiva(false)} height={320} loading={modalMasivaLoading} title='Captura Masiva' >
                {
                    uploaded ? 
                    <div>
                        <div style={{height: 270}}>
                            <DataTable
                                headers={masivaHeaders}
                                data={tableMasiva}
                            />
                        </div>
                    </div> :
                    <div style={{height: 270}}>
                        <h4 className='masiva-titulo'>Instrucciones</h4>
                        <div className='masiva-descripcion'>
                            1. Descargar formato de ejemplo. <br />
                            2. Llenar formato con los campos solicitados. <br />
                            3. Guardar documento en formato .xlsx y respetando nombre de encabezados. <br />
                            4. Importar documento. <br />
                        </div>
                        <div className='masiva-botones'>
                            <BlackButton onClick={downloadFormat}>Descargar Formato</BlackButton>
                            <BlackButton onClick={() => newFile.current.click()}>Importar Formato</BlackButton>
                            <input ref={newFile} type='file' style={{display: 'none'}} onChange={e => uploadFile(e)} />
                        </div>
                    </div>
                }
            </MasivaModal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} key='top,right' open={snackOpen} onClose={() => setSnackOpen(false)} autoHideDuration={2000}>
                <MuiAlert severity={snackType}>
                    {snackMsg}
                </MuiAlert>
            </Snackbar>
        </Contenedor>
    )
}
                    /*
                    <div>
                        <p>Seleccionar docentes:</p>
                        <div className='Coordinador-Table-Box'>
                            <div className='opciones-box' style={{display: 'inline-block', width: '100%'}}>
                                <div style={{float: 'right'}}>
                                    <SearchInput search={searchDocentes} />
                                </div>
                            </div>
                            <div style={{marginTop: '5px', height: 'calc(100% - 45px)'}}>
                                <DataTable 
                                    headers={modalHeaders}
                                    data={docentesFinal}
                                    loading={loadingDocentes}
                                />
                            </div>
                        </div>
                    </div>
                    */