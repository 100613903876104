import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import './style.css';

export default function NewButton(props){
    return(
        <button className={props.disabled ? 'NewButton ButtonCancel' : 'NewButton'} onClick={props.onClick} style={props.style} disabled={props.disabled}>
            <GetAppIcon />
            <span>{props.children}</span>
        </button>
    )
}