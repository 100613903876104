import React, { useState, useEffect } from 'react';
import Contenedor from '../../Utils/Visuales/Contenedor';
import SearchInput from '../../Utils/Visuales/SearchInput';
import DataTable from '../../Utils/DataTables';
import { getRequest } from '../../Utils/Funciones/requester';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MasivaButton from '../../Utils/Botones/MasivaButton';
import BlackButton from '../../Utils/Botones/BlackButton';
import './style.css';
import moment from 'moment';
import Select from 'react-select';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';


export default function Historial(){
    const headers = [
        {
            name: 'Fecha/Hora',
            col: 0
        },
        {
            name: 'Docente',
            col: 1
        },
        {
            name: 'Clave del Curso',
            col: 2
        },
        {
            name: 'Correos',
            col: 3
        },
        {
            name: 'Tipo',
            col: 4
        },
        {
            name: 'Estatus',
            col: 5
        }
    ];
    const [dataFinal, setDataFinal] = useState([])
    const [dataReal, setDataReal] = useState([])
    const [loading, setLoading] = useState(false)
    const [week, setWeek] = useState({
        label: moment().week().toString(),
        value: moment().week()
    })
    const [tipo, setTipo] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const weeks = [
        {
            value: 1,
            label: '1'
        },
        {
            value: 2,
            label: '2'
        },
        {
            value: 3,
            label: '3'
        },
        {
            value: 4,
            label: '4'
        },
        {
            value: 5,
            label: '5'
        },
        {
            value: 6,
            label: '6'
        },
        {
            value: 7,
            label: '7'
        },
        {
            value: 8,
            label: '8'
        },
        {
            value: 9,
            label: '9'
        },
        {
            value: 10,
            label: '10'
        },
        {
            value: 11,
            label: '11'
        },
        {
            value: 12,
            label: '12'
        },
        {
            value: 13,
            label: '13'
        },
        {
            value: 14,
            label: '14'
        },
        {
            value: 15,
            label: '15'
        },
        {
            value: 16,
            label: '16'
        },
        {
            value: 17,
            label: '17'
        },
        {
            value: 18,
            label: '18'
        },
        {
            value: 19,
            label: '19'
        },
        {
            value: 20,
            label: '20'
        },
        {
            value: 21,
            label: '21'
        },
        {
            value: 22,
            label: '22'
        },
        {
            value: 23,
            label: '23'
        },
        {
            value: 24,
            label: '24'
        },
        {
            value: 25,
            label: '25'
        },
        {
            value: 26,
            label: '26'
        },
        {
            value: 27,
            label: '27'
        },
        {
            value: 28,
            label: '28'
        },
        {
            value: 29,
            label: '29'
        },
        {
            value: 30,
            label: '30'
        },
        {
            value: 31,
            label: '31'
        },
        {
            value: 32,
            label: '32'
        },
        {
            value: 33,
            label: '33'
        },
        {
            value: 34,
            label: '34'
        },
        {
            value: 35,
            label: '35'
        },
        {
            value: 36,
            label: '36'
        },
        {
            value: 37,
            label: '37'
        },
        {
            value: 38,
            label: '38'
        },
        {
            value: 39,
            label: '39'
        },
        {
            value: 40,
            label: '40'
        },
        {
            value: 41,
            label: '41'
        },
        {
            value: 42,
            label: '42'
        },
        {
            value: 43,
            label: '43'
        },
        {
            value: 44,
            label: '44'
        },
        {
            value: 45,
            label: '45'
        },
        {
            value: 46,
            label: '46'
        },
        {
            value: 47,
            label: '47'
        },
        {
            value: 48,
            label: '48'
        },
        {
            value: 49,
            label: '49'
        },
        {
            value: 50,
            label: '50'
        },
        {
            value: 51,
            label: '51'
        },
        {
            value: 52,
            label: '52'
        },
        {
            value: 53,
            label: '53'
        },
    ]
    const tipos = [
        {
            value: 'todos',
            label: 'Todos'
        },
        {
            value: 'Lista Verificacion',
            label: 'Lista Verificacion'
        },
        {
            value: 'Concentrado',
            label: 'Concentrado'
        }
    ]
    const [docentes, setDocentes] = useState([])
    const [docente, setDocente]= useState({
        value: 'todos',
        label: 'Todos'
    })
    const [dataDownload, setDataDownload] = useState([])
    const [fechaInicio, setFechaInicio] = useState(moment().startOf('isoWeek'));
    const [fechaFin, setFechaFin] = useState(moment().endOf('isoWeek'));

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    useEffect(() => {
        const pageController = new AbortController()
        const pageSignal = pageController.signal
        fetchData(pageSignal)
        return function cleanup(){
            pageController.abort()
        }
    }, [])

    useEffect(() => {
        let auxData = dataReal
        if(tipo.value !== 'todos'){
            auxData = dataReal.filter(row => row[4] === tipo.value)
        }

        if(docente.value !== 'todos'){
            auxData = auxData.filter(row => row[1] === docente.value)
        }

        setDataFinal(auxData)
    }, [tipo, docente])

    async function fetchData(signal){
        setLoading(true)
        let url = `/historial?fecha_inicio=${moment(fechaInicio).format('YYYY-MM-DD')}&fecha_fin=${moment(fechaFin).format('YYYY-MM-DD')}`
        if(tipo.value !== 'todos'){
            url += `&tipo=${tipo.value}`
        }
        if(docente.value !== 'todos'){
            url += `&persona=${docente.value}`
        }
        try {
            let response = await getRequest(url, signal)
            if(response.s === 'OK'){
                let data = []
                let data2 = []
                let dataDocentes = [{
                    value: 'todos',
                    label: 'Todos'
                }]
                if(response.d){
                    response.d.forEach(registro => {
                        data.push([
                            moment(registro.fecha_hora).format('DD-MM-YYYY hh:mm'),
                            registro.persona,
                            registro.clave_curso,
                            registro.correos,
                            registro.tipo,
                            registro.enviado ? <span className='enviado'>Enviado</span> : <span className='no-enviado'>Rechazado</span>
                        ])
                        data2.push({
                            Fecha_hora: moment(registro.fecha_hora).format('DD-MM-YYYY hh:mm'),
                            Persona: registro.persona,
                            Clave_Curso: registro.clave_curso,
                            Correo: registro.correo,
                            Tipo: registro.tipo,
                            Estatus: registro.tipo ? 'Enviado' : 'No Enviado'
                        })
                        dataDocentes.push({
                            value: registro.persona,
                            label: registro.persona
                        })
                    })
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                setDataReal(data)
                setDataFinal(data)
                setDataDownload(data2)
                setDocentes(dataDocentes)
            } else {
                setDataFinal([])
                setDataReal([])
            }
            setLoading(false)
        } catch (error) {

        }
    }

    function downloadHistorial(){
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const ws = XLSX.utils.json_to_sheet(dataDownload)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], {type: fileType})
        let today = moment().format('YYYY/MM/DD')
        FileSaver.saveAs(data, `Historial ${today}.xlsx`)
    }

    function search(text){
        text = text.toLowerCase()
        let result = dataReal.filter(row => {
            if(row[0].toLowerCase().includes(text)) return true
            else if(row[1].toLowerCase().includes(text)) return true
            else if(row[2].toLowerCase().includes(text)) return true
            else if(row[3].toLowerCase().includes(text)) return true
            else if(row[4].props.children.toLowerCase().includes(text)) return true
            return false
        })
        setDataFinal(result)
    }

    return(
        <Contenedor title='Historial'>
            <div className='opciones-bar'>
                <div className='tab-filtros'>
                    <MasivaButton onClick={downloadHistorial}>Descargar</MasivaButton>
                    <label>Fecha Inicio:</label>
                    <div className='fecha-historial'>
                        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 999999999}}>
                            <DatePicker value={fechaInicio} onChange={(e)=>{setFechaInicio(e)}} clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy' />
                        </MuiPickersUtilsProvider>
                    </div>
                    <label>Fecha Fin:</label>
                    <div className='fecha-historial'>
                        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 999999999}}>
                            <DatePicker value={fechaFin} onChange={(e)=>{setFechaFin(e)}} clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy' />
                        </MuiPickersUtilsProvider>
                    </div>
                    <label>Tipo:</label>
                    <Select
                        options={tipos}
                        className='select-tipos'
                        classNamePrefix='select-search'
                        value={tipo}
                        onChange={x => setTipo(x)}
                    />
                    <label>Docente:</label>
                    <Select 
                        options={docentes}
                        className='select-docente-largo'
                        classNamePrefix='select-search'
                        value={docente}
                        onChange={x => setDocente(x)}
                    />
                    <BlackButton style={{marginLeft: '20px'}} onClick={() => fetchData()}>Buscar</BlackButton>
                </div>
                <div style={{paddingTop: '5px', marginLeft: 5, position: 'relative', float: 'right'}}>
                    <SearchInput search={search} />
                </div>
            </div>
            <div style={{height: 'calc(100% - 115px)'}}>
                <DataTable 
                    headers={headers}
                    data={dataFinal}
                    loading={loading}
                    paginate
                />
            </div>
        </Contenedor>
    )
}