import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import DataTables from '../../Utils/DataTables';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import Select from 'react-select'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useSnackbar } from 'notistack';
import NivelesContext from '../../Utils/Providers/NivelesProvider';
import { Bar } from "react-chartjs-2";
import MultiSelect from '../../Utils/MultiSelect';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AlcanceCriterios (props){
    const headers = [
        {
            name:'Clave del Criterio',
            col:0
        },
        {
            name: 'Descripción del Criterio',
            col: 1
        },
        {
            name: 'Nivel',
            col: 2
        },
        {
            name: 'Cumplimiento',
            col: 3
        }
    ];
    const [data, setData] = useState(props.data);
    const [dataFinal, setDataFinal] = useState(props.data);
    const [tableLoading, setTableLoading] = useState(false);
    const [niveles, setNiveles] = useState(useContext(NivelesContext).state.levels);
    const [nivel, setNivel] = useState(props.nivel);
    const [cycle, setCycle] = useState(props.ciclo);
    const [year,setYear] = useState(props.year);
    const [countComparadores,setCountComparadores] = useState(0)
    const [opcionesCriterios,setOpcionesCriterios] = useState([])
    const [valueCriterios,setValueCriterios] = useState("")
    const [quantityCriterios,setQuantityCriterios] =  useState(0)
    const [quantityPrevia,setQuantityPrevia] = useState(0)
    const [estructuracriterios,setEstructuraCriterios] =  useState([])
    const [cycles, setCycles] = useState([
        {
            value: '01',
            label: '01'
        },
        {
            value: '02',
            label: '02'
        },
        {
            value: '03',
            label: '03'
        }
    ]);
    const [weeks, setWeeks] = useState([])
    const [week, setWeek] = useState(props.semana)
    const [docente, setDocente] = useState(props.docente)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [reRender, setReRender] = useState(props.firstTime ? true : false)
    const [resultadosGurdados,setResultadosGuardados] = useState([])
    const [dataGrafica,setDataGrafica] = useState({
        labels: [],
        datasets: []
    })

    const [dataGraficaFinal,setDataGraficaFinal] = useState({
        labels: [],
        datasets: []
    })

    const [colors,setColors] = useState([
        '24,106,59',
        '241,196,15',
        '27,79,114',
        '123,125,125',
        '120,40,31',
        '23,32,42',
        '230,126,34',
        '130,224,170',
        '142,68,173',
        '237,187,153',
    ])


    useEffect(() => {
        if(nivel !== '' && props.firstTime){
            fetchData()
        }
    }, [])

    useEffect(() => {
        if(props.firstTime){
            if(niveles.length > 0){
                setNivel(niveles[0]);
                props.setNivel(niveles[0])
            } else {
                setData([]);
                setDataFinal([]);
                props.changeIndicadores(0, '-', '-');
                props.loadingIndicadores(false);
                setTableLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if(nivel !== ''){
            let nuevasSemanas = []
            for(let i = 0; i < nivel.semanas; i++){
                nuevasSemanas.push({
                    value: i+1,
                    label: i+1
                })
            }
            setWeeks(nuevasSemanas);
            if(reRender){
                setWeek(nuevasSemanas[0]);
                props.setSemana(nuevasSemanas[0])
            }
        }
    }, [nivel])

    function handleChangeCriterios(value){
        let escala = value.split(',')
        let myDataGrafica = [...dataGrafica['datasets']]
        estructuracriterios.forEach((reg,i)=>{
            let nuevosCriterios = []
            escala.forEach(criterio=>{
                nuevosCriterios.push(reg[criterio])
            })
            myDataGrafica[i]['data'] = nuevosCriterios
        })
        setValueCriterios(value)
        setDataGraficaFinal({datasets:myDataGrafica,labels:escala})
    }

    async function fetchData(){
        try {
            if(nivel !== ''){
                setTableLoading(true)
                props.loadingIndicadores(true)
                let json = {
                    year:year.value,
                    semana: week.value,
                    nivel: nivel.value,
                    ciclo: cycle.value
                }
                if(docente.value!=="todos"){
                    json.id_docente=docente.value
                }
                let response = await postRequest('/reporte/alcance/criterio', json)
                if(response.s === 'ok' || response.s === 'OK'){
                    let newData = []
                    let totalCriterios = 0
                    let alcanceMayor = []
                    let alcanceMenor = []
                    let datasets = []
                    let criterios_respuesta = []
                    let criterios_estructura = {}
                    if(response.d){
                        let valores_respuesta = []
                        let opciones_criterios = [{
                            label:'Todos',
                            value:'0'
                        }]
                        alcanceMayor = [0, response.d.resultado[0].descripcion.slice(0,20) + '...']
                        alcanceMenor = [100, response.d.resultado[0].descripcion.slice(0,20) + '...']
                        totalCriterios = response.d.resultado.length
                        response.d.resultado.forEach(criterio => {
                            criterios_respuesta.push(criterio['clave'])
                            opciones_criterios.push({
                                label:criterio['clave']+ ' ' + criterio['descripcion'],
                                value:criterio['clave']
                            })
                            valores_respuesta.push(criterio['porcentaje_alcance'] === "N/A" ? 0 : criterio['porcentaje_alcance'])
                            criterios_estructura[criterio['clave']] = criterio['porcentaje_alcance'] === "N/A" ? 0 : criterio['porcentaje_alcance']
                            if(criterio.porcentaje_alcance==="N/A"){
                                newData.push([criterio.clave,criterio.descripcion, criterio.nivel, `NA`])
                            }else{
                                newData.push([criterio.clave,criterio.descripcion, criterio.nivel, `${criterio.porcentaje_alcance}%`])
                            }
                            if(parseFloat(criterio.porcentaje_alcance) > alcanceMayor[0]){
                                alcanceMayor = [parseFloat(criterio.porcentaje_alcance), criterio.descripcion.slice(0,20) + '...',criterio.clave]
                            }
                            if(parseFloat(criterio.porcentaje_alcance) < alcanceMenor[0]){
                                alcanceMenor = [parseFloat(criterio.porcentaje_alcance), criterio.descripcion.slice(0,20) + '...',criterio.clave]
                            }
                        })
                        
                        let dataset = {
                            label:json['year'] +  '-C'+json['ciclo']+'-'+json['nivel']+'-S'+json['semana'],
                            backgroundColor: `rgba(${colors[0]})`,
                            borderColor: `rgba(${colors[0]},1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(${colors[0]})`,
                            hoverBorderColor: `rgba(${colors[0]},1)`,
                            data: valores_respuesta   
                        }
                        datasets = [dataset]
                        setEstructuraCriterios([criterios_estructura])
                        setResultadosGuardados(response.d.resultado)
                        setOpcionesCriterios(opciones_criterios)
                        

                    }
                    setDataGrafica({...dataGrafica,labels:criterios_respuesta,datasets:datasets})
                    setDataGraficaFinal({...dataGrafica,labels:criterios_respuesta,datasets:datasets})
                    setValueCriterios(criterios_respuesta.join(','))
                    setCountComparadores(0)
                    let comparativaComp = <div className='contenedor-comparativa'>
                        <p className='contenedor-materia materia-left'>Clave Criterio:{alcanceMayor[2]}</p>
                        <span>{alcanceMayor[0]}%</span>
                        <ArrowUpwardIcon />
                        <div>/</div>
                        <ArrowDownwardIcon />
                        <span>{alcanceMenor[0]}%</span>
                        <p className='contenedor-materia materia-right'>Clave Criterio:{alcanceMenor[2]}</p>
                    </div>
                    setData(newData)
                    setDataFinal(newData)
                    setTableLoading(false)
                    props.setData(newData)
                    if(newData.length > 0){
                        props.changeIndicadores(totalCriterios, comparativaComp, response.d.indicador.promedio === 'NaN' ? '0%' : `${Math.round(response.d.indicador.promedio * 10)/10}%`,year.value,cycle.value,week.value,nivel.value,0)
                    } else {
                        console.log('paso')
                        props.changeIndicadores(0, '-', '-',year.value,cycle.value,week.value,nivel.value,0)
                    }
                    props.loadingIndicadores(false)
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            }
        } catch (error){

        }
    }

    async function fetchComparacion(){
        props.loadingIndicadores(true)
        try {
            let json = props.filtrosComparacion
            setTableLoading(true)
            if(docente.value!=="todos"){
                json.id_docente=docente.value
            }
            let response = await postRequest('/reporte/alcance/criterio', json)
            if(response.s === 'ok' || response.s === 'OK'){
                let newData = []
                let totalCriterios = 0
                let alcanceMayor = []
                let alcanceMenor = []
                let criterios_respuesta = []
                let datasets = []
                let datasetsFinal = []
                let criterios_estructura = {}
                if(response.d){
                    setResultadosGuardados([...resultadosGurdados,response.d.resultado])
                    let valores_respuesta = []
                    let valores_respuesta_final = []
                    alcanceMayor = [0, response.d.resultado[0].descripcion.slice(0,20) + '...']
                    alcanceMenor = [100, response.d.resultado[0].descripcion.slice(0,20) + '...']
                    totalCriterios = response.d.resultado.length
                    response.d.resultado.forEach(criterio => {
                        criterios_respuesta.push(criterio['clave'])
                        valores_respuesta.push(criterio['porcentaje_alcance'] === "N/A" ? 0 : criterio['porcentaje_alcance'])
                        criterios_estructura[criterio['clave']] = criterio['porcentaje_alcance'] === "N/A" ? 0 : criterio['porcentaje_alcance']
                        if(criterio.porcentaje_alcance==="N/A"){
                            newData.push([criterio.clave,criterio.descripcion, criterio.nivel, `NA`])
                        }else{
                            newData.push([criterio.clave,criterio.descripcion, criterio.nivel, `${criterio.porcentaje_alcance}%`])
                        }
                        if(parseFloat(criterio.porcentaje_alcance) > alcanceMayor[0]){
                            alcanceMayor = [parseFloat(criterio.porcentaje_alcance), criterio.descripcion.slice(0,20) + '...',criterio.clave]
                        }
                        if(parseFloat(criterio.porcentaje_alcance) < alcanceMenor[0]){
                            alcanceMenor = [parseFloat(criterio.porcentaje_alcance), criterio.descripcion.slice(0,20) + '...',criterio.clave]
                        }
                    })
                    valueCriterios.split(',').forEach(criterio=>{
                        valores_respuesta_final.push(criterios_estructura[criterio])
                    })
                    let random_color = `${Math.random() * (255 - 0)},${Math.random() * (255 - 0)},${Math.random() * (255 - 0)}`
                    let dataset = {
                        label:json['year'] + '-C'+json['ciclo']+'-'+json['nivel']+'-S'+json['semana'],
                        backgroundColor: `rgba(${colors[dataGrafica['datasets'].length]})`,
                        borderColor: `rgba(${colors[dataGrafica['datasets'].length]},1)`,
                        borderWidth: 1,
                        hoverBackgroundColor: `rgba(${colors[dataGrafica['datasets'].length]})`,
                        hoverBorderColor: `rgba(${colors[dataGrafica['datasets'].length]},1)`,
                        data: valores_respuesta   
                    }

                    let datasetFinal = {
                        label:json['year'] +  '-C'+json['ciclo']+'-'+json['nivel']+'-S'+json['semana'],
                        backgroundColor: `rgba(${colors[dataGrafica['datasets'].length]})`,
                        borderColor: `rgba(${colors[dataGrafica['datasets'].length]},1)`,
                        borderWidth: 1,
                        hoverBackgroundColor: `rgba(${colors[dataGrafica['datasets'].length]})`,
                        hoverBorderColor: `rgba(${colors[dataGrafica['datasets'].length]},1)`,
                        data: valores_respuesta_final  
                    }
                    setColors([...colors,random_color])
                    datasets = [...dataGrafica['datasets'],dataset]
                    datasetsFinal  = [...dataGrafica['datasets'],datasetFinal]
                    setEstructuraCriterios([...estructuracriterios,criterios_estructura])

                }else{
                    setTableLoading(false)

                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    props.eliminarComparador(countComparadores+1)
                    props.loadingIndicadores(false)
                    return 
                }
                setDataGrafica({...dataGrafica,labels:criterios_respuesta,datasets:datasets})

                setDataGraficaFinal({...dataGrafica,labels:valueCriterios.split(','),datasets:datasetsFinal})
                setCountComparadores(countComparadores + 1)
                props.loadingIndicadores(false)
                let comparativaComp = <div className='contenedor-comparativa'>
                    <p className='contenedor-materia materia-left'>Clave Criterio:{alcanceMayor[2]}</p>
                    <span>{alcanceMayor[0]}%</span>
                    <ArrowUpwardIcon />
                    <div>/</div>
                    <ArrowDownwardIcon />
                    <span>{alcanceMenor[0]}%</span>
                    <p className='contenedor-materia materia-right'>Clave Criterio:{alcanceMenor[2]}</p>
                </div>
                setData(newData)
                setDataFinal(newData)
                setTableLoading(false)
                props.setData(newData)
                if(newData.length > 0){
                    props.changeIndicadores(
                        totalCriterios, 
                        comparativaComp, 
                        response.d.indicador.promedio === 'NaN' ? '0%' : `${Math.round(response.d.indicador.promedio * 10)/10}%`,
                        props.filtrosComparacion.year,
                        props.filtrosComparacion.ciclo,
                        props.filtrosComparacion.semana,
                        props.filtrosComparacion.nivel,
                        countComparadores+1
                    )
                } else {
                    props.changeIndicadores(0, 0, '-',props.filtrosComparacion.year,props.filtrosComparacion.ciclo,props.filtrosComparacion.semana,props.filtrosComparacion.nivel,countComparadores+1)
                }
                setTableLoading(false)
                props.loadingIndicadores(false)
            } else {
                enqueueSnackbar(response.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
        } catch (error){

        }
    }


    useEffect(() => {
        if(!props.firstTime){
            if(reRender){
                fetchData()
            } else {
                setReRender(true)
            }
        } else {
            props.setFirstTime(false)
        }
    }, [year, week, docente, cycle])

    useEffect(() =>{
        if(props.comparadoresQuantity > 1){
            fetchComparacion()
        }else{
            if(props.comparadoresQuantity == 0 && props.data.length != 0){
                fetchData()
            }
        }
    },[props.comparadoresQuantity])

    useEffect(() => {
        function filtrar(){
            let dataNueva = []
            if(niveles.value !== 'todos'){
                dataNueva = data.filter(row => {
                    return row[1].toLowerCase().includes(niveles.value.toLowerCase())
                })
            } else {
                dataNueva = data
            }
            setDataFinal(dataNueva)
        }
        
        //filtrar()
    }, [data, niveles])

    useEffect(() => {
        if(reRender){
            let semanas = []
            for(let i = 1; i < nivel.semanas; i++){
                semanas.push({
                    value: i,
                    label: i
                })
            }
            setWeeks(semanas)
        }
    }, [nivel])

    const options={
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        type:'bar',
        scales: {
            xAxes: [{
                barThickness : 73,
                categorySpacing: 0
            }]
        }
    }

    return(
        <>
            <div className='tab-filtros'>
                {
                    props.comparadoresQuantity <= 1 &&
                    <>
                        <p style={{fontWeight: 700}}>Filtrar por:</p>                
                        <p>Año:</p>
                        <Select 
                            isDisabled = {props.comparadoresQuantity > 0}
                            options={props.yearsFiltro}
                            className='select-search mas-ancho'
                            classNamePrefix='select-search'
                            value={year}
                            onChange={x => {
                                setYear(x)
                                props.setYear(x)
                            }}
                        />  
                        <p>Ciclo:</p>
                        <Select 
                            isDisabled = {props.comparadoresQuantity > 0}
                            options={cycles}
                            className='select-search'
                            classNamePrefix='select-search'
                            value={cycle}
                            onChange={x => {
                                setCycle(x)
                                props.setCiclo(x)
                            }}
                        />
                        <p>Nivel:</p>
                        <Select 
                            isDisabled = {props.comparadoresQuantity > 0}
                            options={niveles}
                            className='select-search'
                            classNamePrefix='select-search'
                            value={nivel}
                            onChange={x => {
                                setNivel(x)
                                props.setNivel(x)
                            }}
                        />
                        <p>Semana:</p>
                        <Select 
                            isDisabled = {props.comparadoresQuantity > 0}
                            options={weeks}
                            className='select-weeks'
                            classNamePrefix='select-search'
                            value={week}
                            onChange={x => {
                                setWeek(x)
                                props.setSemana(x)
                            }}
                        />
                        <p>Docente:</p>
                        <Select 
                            options={props.docentes}
                            className='select-docente-largo'
                            classNamePrefix='select-search'
                            value={docente}
                            onChange={x => {
                                setDocente(x)
                                props.setDocente(x)
                            }}
                        />
                    </>
                }
                
            </div>
            {
                props.comparadoresQuantity > 1 ?
                <div id="container-chart-bar">
                    {
                        tableLoading ? 
                        <div style={{textAlign: 'center', marginTop: '10px', position: 'absolute', width: '100%'}}>
                            <CircularProgress size={50} />
                        </div>:
                        <>
                            <span>Seleccionar criterio:</span>
                            <MultiSelect 
                                options={opcionesCriterios}
                                selecteds={valueCriterios}
                                cantidad={quantityCriterios}
                                onSet={handleChangeCriterios}
                                setCantidad={setQuantityCriterios} 
                                seleccionPrevia={quantityPrevia}
                            />
                            <div>
                                <Bar  data={dataGraficaFinal} options={options} />
                            </div>
                        </>
                    }

                </div>
                :

                <div style={{height: 'calc(100% - 70px)', margin: '0 20px'}}>
                    <DataTables headers={headers} data={dataFinal} loading={tableLoading} centrar={[1,2,3,4]} />
                </div>
            }

        </>
    )
}