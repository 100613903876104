import React, { useState, useEffect, useContext } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import NewButton from '../../Utils/Botones/NewButton'
import SearchInput from '../../Utils/Visuales/SearchInput'
import DataTable from '../../Utils/DataTables'
import EditButton from '../../Utils/Botones/EditButton'
import NewModal from '../../Utils/Visuales/NewModal'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
} from '@material-ui/pickers'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import * as XLSX from 'xlsx'
import LargeModal from '../../Utils/Visuales/LargeModal'
import MailModal from '../../Utils/Visuales/MailModal'
import Calendar from '../../Utils/CalendarTable/Calendar'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import { useSnackbar } from 'notistack'
import { cleanup } from '@testing-library/react'
import Can from '../../Utils/Funciones/can'
import LinearProgress from '@material-ui/core/LinearProgress'
import NivelesContext from '../../Utils/Providers/NivelesProvider'
import { STYLES } from '../../Utils/variables'

export default function Calendario(props) {
  const headers = [
    { col: 0, name: 'Folio' },
    { col: 1, name: 'Año' },
    { col: 2, name: 'Ciclo' },
    { col: 3, name: 'Nivel' },
    { col: 4, name: 'Módulo' },
    { col: 5, name: 'Fecha Inicio' },
    { col: 6, name: 'Fecha Fin' },
    { col: 7, name: 'Total de Semanas' },
    { col: 8, name: 'Status' },
    { col: 9, name: 'Acción' },
  ]
  const [dataOriginal, setDataOriginal] = useState([])
  const [dataFinal, setDataFinal] = useState([])
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackType, setSnackType] = useState('')
  const [snackMsg, setSnackMsg] = useState('')
  const [tableLoading, setTableLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [loadingModificarSemana, setLoadingModificarSemana] = useState(false)
  //New modal states
  const [modalNew, setModalNew] = useState(false)
  const [year, setYear] = useState('')
  const [filter, setFilter] = useState({ year: moment(), search: '' })
  const [cycle, setCycle] = useState('')
  const [cambiadoInicio, setCambiadoInicio] = useState(false)
  const [cambiadoFin, setCambiadoFin] = useState(false)
  const [confirmarMasiva, setConfirmarMasiva] = useState(false)
  const [semanasMasivas, setSemanasMasivas] = useState({})
  const [cargaMasiva, setCargaMasiva] = useState(false)
  const [estructuraIds, setEstructuraIds] = useState({})
  const [porcentajeMasiva, setPorcentajeMasiva] = useState(0)
  const [cargandoMasiva, setCargandoMasiva] = useState(false)
  const [log, setLog] = useState([])
  const [detalleRequest, setDetalleRequest] = useState({
    nombre: '',
    fecha_fin: '',
    fecha_inicio: '',
  })
  const cycles = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
  ]
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [startClasDate, setStartClasDate] = useState(moment())
  const [endClasDate, setEndClasDate] = useState(moment())
  const [levels, setLevels] = useState(useContext(NivelesContext).state.levels)
  const [level, setLevel] = useState('')
  const [totalWeeks, setTotalWeeks] = useState(0)
  const [loadingModal, setLoadingModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  //Modal Calendario
  const [modalCalendar, setModalCalendar] = useState(false)
  const [calendarId, setCalendarId] = useState('')
  const [calendarLoading, setCalendarLoading] = useState(false)
  const [calendarActive, setCalendarActive] = useState(0)
  const [calendarYear, setCalendarYear] = useState('')
  const [calendarCycle, setCalendarCycle] = useState('')
  const [calendarLevel, setCalendarLevel] = useState('')
  const [calendarCreatedAt, setCalendarCreatedAt] = useState('')
  const [calendarCreatedBy, setCalendarCreatedBy] = useState('')
  const [calendarUpdatedAt, setCalendarUpdatedAt] = useState('')
  const [calendarHeaders, setCalendarHeaders] = useState([])
  const [calendarWeeks, setCalendarWeeks] = useState([])
  const [calendarModulo, setCalendarModulo] = useState('')
  const [calendarInitDate, setCalendarInitDate] = useState('')
  const [calendarEndDate, setCalendarEndDate] = useState('')
  const [calendarInitCurDate, setCalendarInitCurDate] = useState('')
  const [calendarEndCurDate, setCalendarEndCurDate] = useState('')
  const [calendarTotalWeeks, setCalendarTotalWeeks] = useState('')

  const [modalModificarSemana, setModificarSemana] = useState(false)
  const [startDateModificarSemana, setStartDateModificarSemana] = useState(moment())
  const [endDateModificarSemana, setEndDateModificarSemana] = useState(moment())
  const [detalleCalendario, setDetalleCalendario] = useState([])
  const [idSemana, setIdSemana] = useState('')

  const [modalModificarCriterio, setModificarCriterio] = useState(false)
  const [startDateModificarCriterio, setStartDateModificarCriterio] = useState(moment())
  const [endDateModificarCriterio, setEndDateModificarCriterio] = useState(moment())
  const [loadingModificarCriterio, setLoadingModificarCriterio] = useState(false)
  const [semanaEdit, setSemanaEdit] = useState(-1)
  const [criterioEdit, setCriterioEdit] = useState('')
  const [maxDate, setMaxDate] = useState(moment())
  const [minDate, setMinDate] = useState(moment())
  const [criterioId, setCriterioId] = useState('')
  const [logErrores, setLogErrores] = useState(false)
  const [modules, setModules] = useState([
    { value: 'A', label: 'A' },
    { value: 'A1', label: 'A1' },
    { value: 'B', label: 'B' },
    { value: 'B1', label: 'B1' },
    { value: 'Transversal', label: 'Longitudinal' },
    { value: 'Transversal1', label: 'Longitudinal1' },
  ])
  const [modulo, setModulo] = useState('')
  const [modalConfirmacion, setModalConfirmacion] = useState(false)

  useEffect(() => {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    async function fetchData() {
      try {
        setTableLoading(true)
        let res = await getRequest('/calendario', pageSignal)
        if (res.s === 'OK') {
          let data = []
          if (res.d) {
            data = res.d.map((row) => {
              if (row.modulo === 'Transversal') row.modulo = 'Longitudinal'
              return [
                row.id,
                row.año,
                row.ciclo,
                row.nivel,
                row.modulo,
                moment(row.fecha_inicio).format('DD-MM-YYYY'),
                moment(row.fecha_fin).format('DD-MM-YYYY'),
                row.total_semanas,
                row.status ? 'Activo' : 'Inactivo',
                <>
                  <Can I='edit' a='calendario'>
                    <EditButton onClick={() => openModalCalendar(row.id)}>
                      Criterios
                    </EditButton>
                  </Can>
                </>,
              ]
            })
            data = data.filter(row => row[8] === 'Activo')
          }
          setDataOriginal(data)
          setDataFinal(data)
          setTableLoading(false)
        } else {
          enqueueSnackbar(res.m, STYLES.failure)
        }
      } catch (error) { }
    }
    fetchData()
    return function cleanup() {
      pageController.abort()
    }
  }, [refresh])

  useEffect(() => {
    search(dataOriginal, filter)
  }, [dataOriginal, filter])

  useEffect(() => {
    let firstMonday = moment(startClasDate).isoWeekday('Monday')
    let latestSunday = moment(endClasDate).isoWeekday('Sunday')
    let diff = latestSunday.diff(firstMonday, 'weeks', true)
    setTotalWeeks(Math.ceil(diff))
  }, [startClasDate, endClasDate])

  function handleChangeStart(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrBefore(endDate, 'day')) {
      setStartDate(date)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha de inicio debe ser antes de fecha final')
      setSnackOpen(true)
    }
  }

  function handleChangeClaStart(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrBefore(endClasDate, 'day')) {
      setStartClasDate(date)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha de inicio debe ser antes de fecha final')
      setSnackOpen(true)
    }
  }

  function handleChangeEnd(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrAfter(startDate, 'day')) {
      setEndDate(date)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha final debe ser después de fecha de inicio')
      setSnackOpen(true)
    }
  }

  function handleChangeClaStartModificarSemana(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrBefore(endDateModificarSemana, 'day')) {
      setStartDateModificarSemana(date)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha de inicio debe ser antes de fecha final')
      setSnackOpen(true)
    }
  }

  function handleChangeEndModificarSemana(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrAfter(startDateModificarSemana, 'day')) {
      setEndDateModificarSemana(date)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha final debe ser después de fecha de inicio')
      setSnackOpen(true)
    }
  }

  function handleChangeClaStartModificarCriterio(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrBefore(endDateModificarCriterio, 'day') || !cambiadoFin) {
      setStartDateModificarCriterio(date)
      setCambiadoInicio(true)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha de inicio debe ser antes de fecha final')
      setSnackOpen(true)
    }
  }

  function handleChangeEndModificarCriterio(pickerDate) {
    let date = moment(pickerDate)
    if (
      date.isSameOrAfter(startDateModificarCriterio, 'day') ||
      !cambiadoInicio
    ) {
      setEndDateModificarCriterio(date)
      setCambiadoFin(true)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha final debe ser después de fecha de inicio')
      setSnackOpen(true)
    }
  }

  function handleChangeClaEnd(pickerDate) {
    let date = moment(pickerDate)
    if (date.isSameOrAfter(startClasDate, 'day')) {
      setEndClasDate(date)
    } else {
      setSnackType('error')
      setSnackMsg('Fecha final debe ser después de fecha de inicio')
      setSnackOpen(true)
    }
  }

  function handleChangeSearch(value) {
    setFilter(state => ({ ...state, search: value }))
  }

  function handleChangeYear(value) {
    setFilter(state => ({ ...state, year: value }))
  }

  function search(data = [], filter = {}) {
    try {
      let text = filter?.search?.toLowerCase() || ''
      if (filter?.year) data = data.filter(row => row[1].includes(moment(filter.year).format('YY')))

      if (data.length && text) {
        data = data.filter(row => {
          // else if (row[1].toString().toLowerCase().includes(text)) return true
          if (row[0].toString().toLowerCase().includes(text)) return true
          else if (row[2].toLowerCase().includes(text)) return true
          else if (row[3].toLowerCase().includes(text)) return true
          else if (row[4].toLowerCase().includes(text)) return true
          else if (row[5].toString().toLowerCase().includes(text)) return true
          else if (row[6].toString().toLowerCase().includes(text)) return true
          else if (row[7].toString().toLowerCase().includes(text)) return true
          else if (row[8].toLowerCase().includes(text)) return true
          else return false
        })
      }
      setDataFinal(data)
    } catch (err) { console.error(err) }
  }

  async function openModalCalendar(id) {
    setCalendarLoading(true)
    setModalCalendar(true)
    let res = await getRequest(`/calendario/detalle?id=${id}`)
    if (res.s === 'OK') {
      // setCalendarTipoRegla(res.d.detalle['tipo_regla'])
      // setCalendarCampus(setOptionsSelect(res.d.detalle['lista_campus']))
      // setCalendarEscuelas(setOptionsSelect(res.d.detalle['lista_escuela']))
      setCalendarId(res.d.detalle.id)
      setCalendarActive(res.d.detalle.status)
      setCalendarLevel(res.d.detalle.nivel)
      setCalendarYear(res.d.detalle.year)
      setCalendarCycle(res.d.detalle.ciclo)
      setCalendarCreatedBy(res.d.detalle.creado_por)
      setCalendarCreatedAt(moment(res.d.detalle.fecha_creado).format('DD-MM-YYYY'))
      setCalendarUpdatedAt(moment(res.d.detalle.fecha_actualizado).format('DD-MM-YYYY'))
      if (res.d.detalle.modulo === 'Transversal') {
        setCalendarModulo('Longitudinal')
      } else {
        setCalendarModulo(res.d.detalle.modulo)
      }
      setCalendarInitCurDate(moment(res.d.detalle.fecha_inicio_clases).format('DD-MM-YYYY'))
      setCalendarEndCurDate(moment(res.d.detalle.fecha_fin_clases).format('DD-MM-YYYY'))
      setCalendarInitDate(moment(res.d.detalle.fecha_inicio_curso).format('DD-MM-YYYY'))
      setCalendarEndDate(moment(res.d.detalle.fecha_fin_curso).format('DD-MM-YYYY'))
      setCalendarTotalWeeks(res.d.detalle.total_semanas)
      let criteriosList = res.d.criterios.map((criterio) => ({
        name: criterio.descripcion,
        clave: criterio.clave,
        weeks: [],
      }))
      let weeksQuantity = res.d.detalle.calendario[res.d.detalle.calendario.length - 1].semana
      for (let i = 0; i < criteriosList.length; i++) {
        let weeksCriterios = res.d.detalle.calendario.filter(
          (row) => row.criterios[0].clave_criterio === criteriosList[i].clave
        )
        for (let j = 0; j < weeksCriterios.length; j++) {
          criteriosList[i].weeks.push(weeksCriterios[j].criterios[0].activo);
        }
      }
      let weeksHeader = []
      for (let i = 0; i < weeksQuantity; i++) {
        weeksHeader.push(`Semana ${i + 1}`)
      }
      setDetalleCalendario(res.d.detalle.calendario)
      setCalendarHeaders(weeksHeader)
      setCalendarWeeks(criteriosList)
      setCalendarLoading(false)
    } else {
      enqueueSnackbar(res.m, STYLES.failure)
    }
  }

  const sendCalendar = (activo = 1) => async () => {
    if (!calendarActive) return enqueueSnackbar('Accion Inválida, Criterio Inactivo', STYLES.failure)

    setModalConfirmacion(false)
    let matrix = document.getElementsByClassName('Calendar-Cell')
    let calendario = []
    for (let i = 0; i < calendarHeaders.length; i++) {
      let semana = {
        semana: i + 1,
        criterios: [],
      }
      calendario.push(semana)
    }
    for (let i = 0; i < matrix.length; i = i + calendarHeaders.length) {
      for (let j = 0; j < calendarHeaders.length; j++) {
        let clave = matrix[i + j].getAttribute('clave')
        let bool = matrix[i + j].classList.contains('selected')
        calendario[j].criterios.push({
          clave_criterio: clave,
          activo: bool,
        })
      }
    }
    let json = {
      id: calendarId,
      calendario: calendario,
      activo
    }
    setCalendarLoading(true)
    let res = await postRequest('/calendario/editar', json)
    if (res.s !== 'OK') return enqueueSnackbar(res.m, STYLES.failure)
    setModalCalendar(false)
    enqueueSnackbar(`Calendario actualizado correctamente.`, STYLES.success)
    setRefresh(refresh + 1)
  }

  useEffect(() => {
    setLevel('');
    setYear('');
    setCycle('');
    setModulo('');
    setStartDate(moment());
    setEndDate(moment());
  }, [modalNew]);

  async function sendNuevoCurso() {
    let valido = true;

    if (level === '') {
      document
        .getElementsByClassName('input-level')[0]
        .classList.add('error-input');
      valido = false;
    } else {
      document
        .getElementsByClassName('input-level')[0]
        .classList.remove('error-input');
    }
    if (year === '') {
      document
        .getElementsByClassName('input-year')[0]
        .classList.add('error-input');
      valido = false;
    } else {
      document
        .getElementsByClassName('input-year')[0]
        .classList.remove('error-input');
    }

    if (modulo === '') {
      document
        .getElementsByClassName('input-modulo')[0]
        .classList.add('error-input');
      valido = false;
    } else {
      document
        .getElementsByClassName('input-modulo')[0]
        .classList.remove('error-input');
    }

    if (cycle === '') {
      document
        .getElementsByClassName('input-cycle')[0]
        .classList.add('error-input');
      valido = false;
    } else {
      document
        .getElementsByClassName('input-cycle')[0]
        .classList.remove('error-input');
    }

    if (valido) {
      let json = {
        nivel: level.value,
        year: year,
        ciclo: cycle.value,
        fecha_fin: moment(endDate).format('YYYY-MM-DD'),
        fecha_inicio: moment(startDate).format('YYYY-MM-DD'),
        fecha_inicio_clases: moment(startClasDate).format('YYYY-MM-DD'),
        fecha_fin_clases: moment(endClasDate).format('YYYY-MM-DD'),
        modulo: modulo.value,
        total_semanas: totalWeeks,
      };
      setLoadingModal(true);
      let response = await postRequest('/calendario/nuevo', json);
      setLoadingModal(false);
      if (response.s === 'OK') {
        setModalNew(false);
        setRefresh(refresh + 1);
      } else {
        setSnackMsg(response.m);
        setSnackType('error');
        setSnackOpen(true);
      }
    }
  }

  async function openModalNewCalendar() {
    setLoadingModal(true);
    setModalNew(true);
    setLoadingModal(false);
  }

  async function handleOpenModificarSemana(semana) {
    let myFechaInicio = '';
    let myFechaFin = '';
    for (let i = 0; i < detalleCalendario.length; i++) {
      if (detalleCalendario[i].semana === semana + 1) {
        myFechaInicio = detalleCalendario[i].fecha_inicio;
        myFechaFin = detalleCalendario[i].fecha_fin;
        setIdSemana(detalleCalendario[i].id);
        i = detalleCalendario.length + 1;
      }
    }
    if (myFechaInicio != '') {
      setStartDateModificarSemana(moment(myFechaInicio));
    } else {
      setStartDateModificarSemana(moment());
    }
    if (myFechaFin != '') {
      setEndDateModificarSemana(moment(myFechaFin));
    } else {
      setEndDateModificarSemana(moment());
    }
    setModificarSemana(true);
  }

  async function handleOpenModificarCriterio(semana, criterio) {
    let myFechaInicio = '';
    let myFechaFin = '';
    let myMinimo = '';
    let myMaximo = '';
    for (let i = 0; i < detalleCalendario.length; i++) {
      for (let j = 0; j < detalleCalendario[i].criterios.length; j++) {
        if (
          detalleCalendario[i].criterios[j].clave_criterio === criterio.clave &&
          semana + 1 == detalleCalendario[i].semana
        ) {
          myFechaInicio = detalleCalendario[i].criterios[j].fecha_inicio;
          myFechaFin = detalleCalendario[i].criterios[j].fecha_fin;
          setCriterioId(detalleCalendario[i].criterios[j].id);
          myMinimo = detalleCalendario[i].fecha_inicio;
          myMaximo = detalleCalendario[i].fecha_fin;
          break;
        }
      }
    }

    if (myMaximo === '' || myMinimo === '') {
      enqueueSnackbar(
        'Se debe definir primero intervalo de fechas para la semana ' +
        (semana + 1),
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      return;
    }

    setSemanaEdit(semana + 1);
    setCriterioEdit(criterio.clave);

    if (myMinimo != '') {
      setMinDate(moment(myMinimo));
    } else {
      setMinDate(moment());
    }
    if (myMaximo != '') {
      setMaxDate(moment(myMaximo));
    } else {
      setMaxDate(moment());
    }

    if (myFechaInicio != '') {
      setStartDateModificarCriterio(moment(myFechaInicio));
    } else {
      setStartDateModificarCriterio(moment(myMinimo + ' 00:00'));
    }
    if (myFechaFin != '') {
      setEndDateModificarCriterio(moment(myFechaFin));
    } else {
      setEndDateModificarCriterio(moment(myMinimo + ' 00:00'));
    }

    setModificarCriterio(true);
  }

  async function sendModificarSemana() {
    if (!calendarActive) return enqueueSnackbar('Accion Inválida, Criterio Inactivo', STYLES.failure)

    let body = {
      fecha_inicio: moment(startDateModificarSemana).format('YYYY-MM-DD'),
      fecha_fin: moment(endDateModificarSemana).format('YYYY-MM-DD'),
      id_semana_calendario: idSemana,
    }
    setLoadingModificarSemana(true)
    let res = await postRequest('/calendario/editar/semana', body)
    if (res.s === 'OK') {
      setLoadingModificarSemana(false)
      setModificarSemana(false)
      enqueueSnackbar(res.m, STYLES.success)
      openModalCalendar(calendarId)
    } else {
      setLoadingModificarSemana(false)
      //setModificarSemana(false)
      enqueueSnackbar(res.m, STYLES.failure)
      // openModalCalendar(calendarId)
    }
  }

  async function sendModificarCriterio() {
    if (!calendarActive) return enqueueSnackbar('Accion Inválida, Criterio Inactivo', STYLES.failure)

    let body = {
      fecha_inicio: moment(startDateModificarCriterio).format('YYYY-MM-DD HH:mm'),
      fecha_fin: moment(endDateModificarCriterio).format('YYYY-MM-DD HH:mm'),
      id_calendario_criterio: criterioId,
    }
    setLoadingModificarCriterio(true)
    let res = await postRequest('/calendario/editar/criterio', body)
    if (res.s === 'OK') {
      setLoadingModificarCriterio(false)
      setModificarCriterio(false)
      enqueueSnackbar(res.m, STYLES.success)
      openModalCalendar(calendarId)
    } else {
      setLoadingModificarCriterio(false)
      //setModificarSemana(false)
      enqueueSnackbar(res.m, STYLES.failure)
      // openModalCalendar(calendarId)
    }
  }

  function downloadFile(filename, text) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  function JSONCalendarioSemanas() {
    let formato = {};
    let idsToSend = {};
    calendarHeaders.forEach((nombreSemana, indexSemana) => {
      let myFechaInicio = '';
      let myFechaFin = '';
      formato[nombreSemana] = {};
      formato[nombreSemana].criterios = [];
      let misCriterios = {};
      for (let i = 0; i < detalleCalendario.length; i++) {
        if (detalleCalendario[i].semana === indexSemana + 1) {
          myFechaInicio = detalleCalendario[i].fecha_inicio;
          myFechaFin = detalleCalendario[i].fecha_fin;
          idsToSend[nombreSemana] = detalleCalendario[i].id;
          i = detalleCalendario.length + 1;
        }
      }

      calendarWeeks.forEach((criterio, indexCriterio) => {
        let myFechaInicioCriterio = '';
        let myFechaFinCriterio = '';
        let myActivo = '';
        for (let i = 0; i < detalleCalendario.length; i++) {
          for (let j = 0; j < detalleCalendario[i].criterios.length; j++) {
            if (
              detalleCalendario[i].criterios[j].clave_criterio ===
              criterio.clave &&
              indexSemana + 1 == detalleCalendario[i].semana
            ) {
              myFechaInicioCriterio =
                detalleCalendario[i].criterios[j].fecha_inicio;
              myFechaFinCriterio = detalleCalendario[i].criterios[j].fecha_fin;
              myActivo =
                detalleCalendario[i].criterios[j].activo == true ? 'si' : 'no';
              let clave_semana_criterio =
                nombreSemana + detalleCalendario[i].criterios[j].clave_criterio;
              idsToSend[clave_semana_criterio] =
                detalleCalendario[i].criterios[j].id;
            }
          }
        }
        misCriterios[criterio.clave] = {
          clave: criterio.clave,
          inicio: myFechaInicioCriterio,
          fin: myFechaFinCriterio,
          activo: myActivo,
        };
      });
      formato[nombreSemana].inicio = myFechaInicio;
      formato[nombreSemana].fin = myFechaFin;
      formato[nombreSemana].criterios = misCriterios;
      formato[nombreSemana] = reverseJSON(formato[nombreSemana]);
    });
    if (!Stc(idsToSend, estructuraIds)) {
      setEstructuraIds(idsToSend);
    }
    return formato;
  }

  function ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }

  function delete_row(ws, row_index) {
    var variable = XLSX.utils.decode_range(ws['!ref']);
    for (var R = row_index; R < variable.e.r; ++R) {
      for (var C = variable.s.c; C <= variable.e.c; ++C) {
        ws[ec(R, C)] = ws[ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
  }

  function descargarFormato() {
    const calendarioSemanasFormateado = JSONCalendarioSemanas();
    const calendarioSemanasMat = [];

    // Set headers
    calendarioSemanasMat.push(Object.keys(calendarioSemanasFormateado));
    calendarioSemanasMat[0].unshift('clave', 'descripción');

    // Set fechas de semanas
    calendarioSemanasMat[1] = new Array(calendarioSemanasMat[0].length);
    Object.keys(calendarioSemanasFormateado).forEach((key, index) => {
      calendarioSemanasMat[1][index + 2] =
        calendarioSemanasFormateado[key]['inicio'] === '' ||
          calendarioSemanasFormateado[key]['fin'] === ''
          ? ''
          : formatDateDayFirst(calendarioSemanasFormateado[key]['inicio']) +
          ' ' +
          formatDateDayFirst(calendarioSemanasFormateado[key]['fin']);
    });

    // Set clave y descripcion
    calendarWeeks.forEach((week) => {
      calendarioSemanasMat.push([
        week.clave,
        week.name,
        ...week.weeks.map(() => ''),
      ]);
    });

    // Set criterios
    Object.keys(calendarioSemanasFormateado).forEach((semana, i) => {
      Object.values(calendarioSemanasFormateado[semana].criterios).forEach(
        (criterio, j) => {
          if (criterio.activo === 'si') {
            calendarioSemanasMat[j + 2][i + 2] =
              formatDateDayFirst(criterio.inicio.split(' ')[0]) +
              ' ' +
              criterio.inicio.split(' ')[1] +
              ' ' +
              formatDateDayFirst(criterio.fin.split(' ')[0]) +
              ' ' +
              criterio.fin.split(' ')[1];
          } else {
            calendarioSemanasMat[j + 2][i + 2] = '';
          }
        }
      );
    });

    const sheet = XLSX.utils.json_to_sheet(
      calendarioSemanasMat,
      XLSX.utils.book_new()
    );
    delete_row(sheet, 0);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');

    XLSX.writeFile(
      workbook,
      'Formato_calendario:' +
      calendarYear +
      '-' +
      calendarCycle +
      '-' +
      calendarLevel +
      '-' +
      calendarModulo +
      '-semanas:' +
      calendarTotalWeeks +
      '.xlsx'
    );
  }

  function formatDateYearFirst(date) {
    let newDate =
      date.split('/')[2] + '-' + date.split('/')[1] + '-' + date.split('/')[0];

    return newDate;
  }

  function formatDateDayFirst(date) {
    let newDate =
      date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0];

    return newDate;
  }

  const errorSnackStyles = {
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  }

  async function handleSubirMasiva(e) {
    if (
      e.target.files[0].name.split('.')[
      e.target.files[0].name.split('.').length - 1
      ] !== 'xlsx'
    ) {
      enqueueSnackbar('Archivo no valido', errorSnackStyles);
      return;
    }

    var reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (e) => {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: 'array' });
      var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      var result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      const newJSON = result.reduce((acc, row, i, arr) => {
        // Resize row to match header
        if (row.length < arr[0].length) {
          let remainingRows = arr[0].length - row.length;
          for (let iR = 0; iR < remainingRows; iR++) {
            row.push('');
          }
        }
        row.forEach((column, j) => {
          // Set fecha_inicio y fecha_fin
          if (i === 1 && j >= 2) {
            acc[arr[0][j]] = {
              inicio:
                column.replaceAll(' ', '') === ''
                  ? ''
                  : formatDateYearFirst(column.split(' ')[0]),
              fin:
                column.replaceAll(' ', '') === ''
                  ? ''
                  : formatDateYearFirst(column.split(' ')[1]),
              criterios: [],
            };
          }
        });

        for (let j = 0; j < row.length; j++) {
          // Set criterios
          if (i > 1 && j >= 2 && row[0] !== '') {
            acc[arr[0][j]].criterios = [
              ...acc[arr[0][j]].criterios,
              {
                clave: row[0],
                inicio:
                  row[j] === undefined
                    ? ''
                    : row[j].replaceAll(' ', '') === '' // Set fecha solo si esta incluida en la tabla
                      ? ''
                      : formatDateYearFirst(row[j].split(' ')[0]) +
                      ' ' +
                      row[j].split(' ')[1],
                fin:
                  row[j] === undefined
                    ? ''
                    : row[j].replaceAll(' ', '') === '' // Set fecha solo si esta incluida en la tabla
                      ? ''
                      : formatDateYearFirst(row[j].split(' ')[2]) +
                      ' ' +
                      row[j].split(' ')[3],
                activo:
                  row[j] === undefined
                    ? 'no'
                    : row[j].replaceAll(' ', '') === ''
                      ? 'no'
                      : 'si',
              },
            ];
          }
        }
        return acc;
      }, {});
      validarMasiva(newJSON);
    };
    let fileSave = e.target.files[0];

    if (
      fileSave.name.toLowerCase().includes('txt') &&
      fileSave.size <= 15000000
    ) {
      //setArchivoSubir(e.target.files[0])
      //setNombreImagen(e.target.files[0].name)
    } else {
      if (fileSave.size > 15000000) {
        //toast.error('Archivo supera los 15MB')
      } else {
        //toast.error('Archivo no permitido')
      }
    }
  }

  function Stc(objectext, objectext2) {
    return JSON.stringify(objectext) == JSON.stringify(objectext2);
  }

  function validarFormatoFecha(campo) {
    var RegExPattern = /^\d{2,4}\-\d{1,2}\-\d{1,2}$/;
    if (campo.match(RegExPattern) && campo != '') {
      var fechaf = campo.split('-');
      var year = fechaf[0];
      var month = fechaf[1];
      var day = fechaf[2];
      var date = new Date(year, month, '0');
      if (day - 0 > date.getDate() - 0) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  function validarFormatoFechaHora(campo) {
    var RegExPattern = /^\d{2,4}\-\d{1,2}\-\d{1,2} \d{2}:\d{2}$/;
    if (campo.match(RegExPattern) && campo != '') {
      campo = campo.split(' ')[0];
      var fechaf = campo.split('-');
      var year = fechaf[0];
      var month = fechaf[1];
      var day = fechaf[2];
      var date = new Date(year, month, '0');
      if (day - 0 > date.getDate() - 0) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  function validarMasiva(newJSON) {
    try {
      const oldJSONMasivo = JSONCalendarioSemanas();
      let semanas_distintas = {};
      //Validando newJson
      if (Object.keys(newJSON).length != Object.keys(oldJSONMasivo).length) {
        enqueueSnackbar(
          'La cantidad de semanas de la estructura no coincide con la del calendario',
          errorSnackStyles
        );
        return;
      }
      Object.keys(newJSON).forEach((semana) => {
        let reg = newJSON[semana];
        let criterios_distintos = [];
        if (reg.inicio != '' && !validarFormatoFecha(reg.inicio)) {
          enqueueSnackbar(
            'Fecha inicio de ' + semana + ' no es correcta',
            errorSnackStyles
          );
          return;
        }
        if (reg.fin != '' && !validarFormatoFecha(reg.fin)) {
          enqueueSnackbar(
            'Fecha fin de ' + semana + ' no es correcta',
            errorSnackStyles
          );
          return;
        }
        reg.criterios.forEach((criterio, indexcriterio) => {
          if (
            criterio.inicio != '' &&
            !validarFormatoFechaHora(criterio.inicio)
          ) {
            enqueueSnackbar(
              'Fecha inicio de ' +
              semana +
              '-' +
              criterio.clave +
              ' no es correcta',
              errorSnackStyles
            );
            return;
          }
          if (criterio.fin != '' && !validarFormatoFechaHora(criterio.fin)) {
            enqueueSnackbar(
              'Fecha fin de ' +
              semana +
              '-' +
              criterio.clave +
              ' no es correcta',
              errorSnackStyles
            );
            return;
          }
          let aa = oldJSONMasivo[semana].criterios[criterio.clave].activo;
          if (
            criterio.activo != oldJSONMasivo[semana].criterios[criterio.clave].activo ||
            criterio.activo == 'si' ||
            (
              (oldJSONMasivo[semana].criterios[criterio.clave].fin == '' ||
                oldJSONMasivo[semana].criterios[criterio.clave].inicio == '')
            )
          ) {
            criterios_distintos.push(criterio);
          }
        });
        let aa = oldJSONMasivo[semana];
        if (
          (Object.keys(criterios_distintos).length > 0 ||
            reg.inicio != oldJSONMasivo[semana].inicio ||
            reg.fin != oldJSONMasivo[semana].fin) &&
          (reg.inicio != '' && reg.fin != '')
        ) {
          semanas_distintas[semana] = reg;
          semanas_distintas[semana].criterios = criterios_distintos;
        }
      });

      if (Object.keys(semanas_distintas).length == 0)
        enqueueSnackbar(
          'No se detectaron cambios en calendario',
          errorSnackStyles
        );
      else {
        setSemanasMasivas(semanas_distintas);
        setConfirmarMasiva(true);
      }
    } catch (e) {
      enqueueSnackbar(
        'Error al subir archivo, estructura incorrecta',
        errorSnackStyles
      );
      console.log('Error al subir archivo, estructura incorrecta');
    }
  }

  function reverseJSON(obj) {
    let keys = [];
    if (obj) {
      for (var key in obj) {
        keys.push(key);
      }
    }
    keys.sort();
    keys.reverse();
    var tObj = {};
    var key;
    for (var index in keys) {
      key = keys[index];
      tObj[key] = obj[key];
    }
    return tObj;
  }

  function iniciarMasiva() {
    setCargaMasiva(true);
    setConfirmarMasiva(false);
    let estructuraRequests = [];
    Object.keys(semanasMasivas).forEach((semana) => {
      let reg = semanasMasivas[semana];
      let estructuraRequest = {};
      estructuraRequest['url'] = '/calendario/editar/semana';
      estructuraRequest['body'] = {
        fecha_inicio: moment(reg.inicio).format('YYYY-MM-DD'),
        fecha_fin: moment(reg.fin).format('YYYY-MM-DD'),
        id_semana_calendario: estructuraIds[semana],
      };
      estructuraRequest['detalle'] = {
        nombre: semana,
        fecha_inicio: reg.inicio,
        fecha_fin: reg.fin,
      };
      estructuraRequests.push(estructuraRequest);
      reg.criterios.forEach((criterio) => {
        let estructuraRequestCriterio = {};
        let fecha_inicio = '';
        let fecha_fin = '';
        estructuraRequestCriterio['url'] = '/calendario/editar/criterio';
        if (criterio.activo == 'no') {
          fecha_inicio = reg.inicio + ' 00:00';
          fecha_fin = reg.inicio + ' 00:00';
        } else {
          fecha_inicio = moment(criterio.inicio).format('YYYY-MM-DD HH:mm');
          fecha_fin = moment(criterio.fin).format('YYYY-MM-DD HH:mm');
        }
        estructuraRequestCriterio['body'] = {
          fecha_inicio: fecha_inicio,
          fecha_fin: fecha_fin,
          id_calendario_criterio: estructuraIds[semana + criterio.clave],
          activo: criterio.activo == 'si' ? 1 : 0,
        };
        estructuraRequestCriterio['detalle'] = {
          nombre: semana + ' criterio:' + criterio.clave,
          fecha_inicio: criterio.inicio,
          fecha_fin: criterio.fin,
        };
        estructuraRequests.push(estructuraRequestCriterio);
      });
    });
    if (estructuraRequests.length > 0) {
      sendRequestMasiva(estructuraRequests, 0, []);
    } else {
      enqueueSnackbar('No hay cambios en calendario', errorSnackStyles);
    }
  }

  async function sendRequestMasiva(estructuraRequests, indexRequest, logArray) {
    setDetalleRequest(estructuraRequests[indexRequest].detalle);
    setPorcentajeMasiva((indexRequest / estructuraRequests.length) * 100);
    setCargandoMasiva(true);
    try {
      let response = await postRequest(
        estructuraRequests[indexRequest].url,
        estructuraRequests[indexRequest].body
      );
      if (response.s) {
        if (response.s == 'error') {
          logArray.push(
            estructuraRequests[indexRequest].detalle.nombre + ' : ' + response.m
          );
        }
        if (indexRequest == estructuraRequests.length - 1) {
          setCargandoMasiva(false);
          setPorcentajeMasiva(100);
          enqueueSnackbar(
            'Carga masiva de calendario finalizada,recargando calendario',
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }
          );
          setLog(logArray);
          openModalCalendar(calendarId);
        } else {
          sendRequestMasiva(estructuraRequests, indexRequest + 1, logArray);
        }
      } else {
        enqueueSnackbar(response.m, errorSnackStyles);
      }
    } catch (e) {
      setCargaMasiva(false);
      enqueueSnackbar('Error procesando calendarios', errorSnackStyles);
    }
  }

  const handleDelete = option => async () => {
    if (option === 1) { // Abre modal de confirmacion
      setDeleteModal(true)
    } else if (option === 2) { // Elimina Calendario
      try {
        setLoadingDelete(true)
        let id = 1
        let res = await postRequest(`/calendario/eliminar?id=${id}`)
        console.log({ res })
        if (res.s !== 'OK') return enqueueSnackbar(res.m, STYLES.failure)
        // await timeOut(0.1)
        setRefresh(refresh + 1)
        setDeleteModal(false)
        setModalCalendar(false)
        enqueueSnackbar(`Calendario ${calendarId} Eliminado Exitosamente`, STYLES.success)
      } catch (err) {
        console.error(err)
        enqueueSnackbar(`Ha Ocurrido un Error Eliminando el Calendario ${calendarId}`, STYLES.failure)
      } finally { setLoadingDelete(false) }
    }
  }

  const handleStatus = (option, newStatus) => async () => {
    if (option === 1) { // Abre modal de confirmacion
      setStatusModal(true)
    } else if (option === 2) { // Actualiza el Estatus Calendario
      setStatusModal(false)
      sendCalendar(newStatus)()
    }
  }

  return (
    <Contenedor title='Calendario'>
      <div className='opciones-bar opciones-bar-filter'>
        <div className='tab-filtros' style={{ display: 'block' }}>
          <div>
            <Can I='create' a='calendario'>
              <NewButton onClick={openModalNewCalendar}> Nuevo </NewButton>
            </Can>
          </div>
          <div>
            <p>Año:</p>
            <MuiPickersUtilsProvider
              locale={esLocale}
              utils={DateFnsUtils}
            >
              <DatePicker
                views={['year']}
                value={filter.year}
                onChange={handleChangeYear}
                className='date-year'
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ float: 'right' }}>
            <SearchInput hideIcon search={handleChangeSearch} />
          </div>
        </div>
      </div>

      <div style={{ height: 'calc(100% - 75px)' }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          centrar={[5, 6, 7]}
          loading={tableLoading}
        />
      </div>

      {/* Nuevo Calendario */}
      <NewModal
        title='Nuevo Calendario'
        open={modalNew}
        handleSubmit={sendNuevoCurso}
        loading={loadingModal}
        handleClose={() => setModalNew(false)}
        height={390}
      >
        <div className='nuevoUsuarioForm'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <label>Año (*)</label>
              <input
                maxLength='2'
                placeholder='Ingresa el año...'
                className='input-year'
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Ciclo (*)</label>
              <Select
                options={cycles}
                placeholder='Seleccione ciclo...'
                className='select-modal input-cycle'
                classNamePrefix='select-modal'
                value={cycle}
                onChange={setCycle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Nivel (*)</label>
              <Select
                options={levels}
                placeholder='Seleccione nivel...'
                className='select-modal input-level'
                classNamePrefix='select-modal'
                value={level}
                onChange={setLevel}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Módulo (*)</label>
              <Select
                options={modules}
                placeholder='Seleccione módulo...'
                className='select-modal input-modulo'
                classNamePrefix='select-modal'
                value={modulo}
                onChange={setModulo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Inicio (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 999999999 }}
              >
                <DatePicker
                  clearLabel='Limpiar'
                  cancelLabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy'
                  value={startDate}
                  onChange={handleChangeStart}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Fin (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 999999999 }}
              >
                <DatePicker
                  clearLabel='Limpiar'
                  cancelLabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy'
                  value={endDate}
                  onChange={handleChangeEnd}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Inicio Clases (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 99999999 }}
              >
                <DatePicker
                  clearLabel='Limpiar'
                  cancellabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy'
                  value={startClasDate}
                  onChange={handleChangeClaStart}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Fin Clases (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 9999999 }}
              >
                <DatePicker
                  clearLabel='Limpiar'
                  cancellabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy'
                  value={endClasDate}
                  onChange={handleChangeClaEnd}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Total Semanas:</label>
              <input
                value={totalWeeks}
                onChange={(e) => setTotalWeeks(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          key='top,right'
          open={snackOpen}
          onClose={() => setSnackOpen(false)}
          autoHideDuration={2000}
        >
          <MuiAlert severity={snackType}>{snackMsg}</MuiAlert>
        </Snackbar>
      </NewModal>

      {/* Calendario de Criterios */}
      <LargeModal
        handleSubmit={() => setModalConfirmacion(true)}
        submittable
        disabled={false}
        title='Calendario de Criterios'
        open={modalCalendar}
        handleClose={() => setModalCalendar(false)}
        handleStatus={handleStatus(1)}
        active={calendarActive}
        loading={calendarLoading}
        height={'75vh'}
        descarga={descargarFormato}
        subir={handleSubirMasiva}
      >
        <div className='opciones-bar inputs-modal inputs-calendar'>
          <div style={{ float: 'left' }}>
            <label>Año:</label>
            <input style={{ width: 38 }} value={calendarYear} disabled />
            <label>Ciclo:</label>
            <input style={{ width: 38 }} value={calendarCycle} disabled />
            <label>Nivel:</label>
            <input style={{ width: 38 }} value={calendarLevel} disabled />
          </div>
          <div style={{ float: 'right', marginRight: 20 }}>
            <label>Fecha Inicio:</label>
            <input style={{ width: 78 }} value={calendarInitDate} disabled />
            <label>Fecha Fin:</label>
            <input style={{ width: 78 }} value={calendarEndDate} disabled />
            <label>Fecha Inicio Clases:</label>
            <input style={{ width: 78 }} value={calendarInitCurDate} disabled />
            <label>Fecha Fin Clases:</label>
            <input style={{ width: 78 }} value={calendarEndCurDate} disabled />
          </div>
        </div>
        <div className='opciones-bar inputs-modal inputs-calendar'>
          <div style={{ float: 'left' }}>
            <label>Total Semanas Nivel:</label>
            <input style={{ width: 38 }} value={calendarTotalWeeks} disabled />
            <label>Modulo:</label>
            <input style={{ width: 85 }} value={calendarModulo} disabled />
          </div>
          <div style={{ float: 'right', marginRight: 20 }}>
            <label>Fecha creado:</label>
            <input style={{ width: 78 }} value={calendarCreatedAt} disabled />
            <label>Creado por:</label>
            <input sttyle={{ width: 80 }} value={calendarCreatedBy} disabled />
            <label>Actualizado:</label>
            <input style={{ width: 78 }} value={calendarUpdatedAt} disabled />
          </div>
        </div>
        <div className='Calendar-Container'>
          <Calendar
            handleOpenModificarCriterio={handleOpenModificarCriterio}
            handleOpenModificarSemana={handleOpenModificarSemana}
            weeks={calendarHeaders}
            criterios={calendarWeeks}
            detalleCalendario={detalleCalendario}
            active={calendarActive}
          />
        </div>
      </LargeModal>

      {/* Confirmación */}
      <MailModal
        title='Confirmación'
        open={modalConfirmacion}
        handleClose={() => setModalConfirmacion(false)}
        height={95}
        handleSave={sendCalendar()}
      >
        <p style={{ textAlign: 'center' }}>
          ¿Deseas guardar los cambios realizados?
        </p>
      </MailModal>

      {/* Modificar semana */}
      <NewModal
        title='Modificar semana'
        open={modalModificarSemana}
        handleSubmit={sendModificarSemana}
        loading={loadingModificarSemana}
        handleClose={() => setModificarSemana(false)}
        height={170}
      >
        <div className='nuevoUsuarioForm'>
          <Grid container>
            <Grid item xs={12} md={6}>
              <label>Fecha Inicio (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 99999999 }}
              >
                <DatePicker
                  clearLabel='Limpiar'
                  cancelLabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy'
                  value={startDateModificarSemana}
                  onChange={handleChangeClaStartModificarSemana}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Fin (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 99999999 }}
              >
                <DatePicker
                  clearLabel='Limpiar'
                  cancelLabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy'
                  value={endDateModificarSemana}
                  onChange={handleChangeEndModificarSemana}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      {/* Modificar Criterio */}
      <NewModal
        title='Modificar Criterio'
        open={modalModificarCriterio}
        handleSubmit={sendModificarCriterio}
        loading={loadingModificarCriterio}
        handleClose={() => setModificarCriterio(false)}
        height={170}
      >
        <div className='nuevoUsuarioForm'>
          <Grid container>
            <Grid item xs={12} md={12}>
              <h4>
                Configuración de semana {semanaEdit} criterio {criterioEdit}
              </h4>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Inicio (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 99999999 }}
              >
                <DateTimePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  clearLabel='Limpiar'
                  cancelLabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy HH:mm'
                  value={startDateModificarCriterio}
                  onChange={handleChangeClaStartModificarCriterio}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Fecha Fin (*)</label>
              <MuiPickersUtilsProvider
                locale={esLocale}
                utils={DateFnsUtils}
                style={{ zIndex: 99999999 }}
              >
                <DateTimePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  clearLabel='Limpiar'
                  cancelLabel='Cancelar'
                  className='date-picker'
                  clearable
                  format='dd/MM/yyyy HH:mm'
                  value={endDateModificarCriterio}
                  onChange={handleChangeEndModificarCriterio}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      {/* Carga masiva de calendario */}
      <NewModal
        title='Carga masiva de calendario'
        open={confirmarMasiva}
        handleSubmit={iniciarMasiva}
        loading={false}
        handleClose={() => setConfirmarMasiva(false)}
        height={170}
      >
        <div>
          <p className='centrar-texto'>
            ¿Desea inicar carga masiva calendario?.
          </p>
          <p className='centrar-texto'>
            Se modificaron un total de {Object.keys(semanasMasivas).length}{' '}
            semanas
          </p>
        </div>
      </NewModal>

      {/* Carga masiva de calendario */}
      <NewModal
        title='Carga masiva de calendario'
        open={cargaMasiva}
        handleSubmit={() => { }}
        loading={false}
        handleClose={() => {
          if (cargandoMasiva) {
            enqueueSnackbar(
              'No se puede cerrar mientras se realiza carga masiva',
              errorSnackStyles
            );
          } else {
            setCargaMasiva(false);
          }
        }}
        noFooter={cargandoMasiva}
        noSaveBtn
        height={200}
      >
        <div>
          <div style={{ height: 100, textAlign: 'center' }}>
            <p>Modificando {detalleRequest.nombre}</p>
            <p>Fecha inicio: {detalleRequest.fecha_inicio}</p>
            <p>Fecha fin: {detalleRequest.fecha_fin}</p>
          </div>
          <LinearProgress variant='determinate' value={porcentajeMasiva} />
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            {cargandoMasiva ? null : (
              <button
                className='black-btn'
                onClick={() => {
                  setLogErrores(true);
                }}
              >
                Ver log errores
              </button>
            )}
          </div>
        </div>
      </NewModal>

      {/* Log fechas con error */}
      <LargeModal
        disabled={false}
        title='Log fechas con error'
        open={logErrores}
        handleClose={() => setLogErrores(false)}
        loading={false}
        height={'75vh'}
      >
        <div
          className='opciones-bar inputs-modal inputs-calendar'
          style={{ overflow: 'auto', maxHeight: 'calc(100% - 58px)' }}
        >
          {log.map((x) => (
            <p>{x}</p>
          ))}
        </div>
      </LargeModal>

      {/* Eliminar Calendario */}
      <NewModal
        title={`Eliminar Calendario`}
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        height={100}
        loading={loadingDelete}
        handleSubmit={handleDelete(2)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>¿Desea Eliminar el Calendario {calendarId}?</p>
        </div>
      </NewModal>

      {/* Cambiar Estatus Calendario */}
      <NewModal
        title={`${calendarActive ? 'Desactivar' : 'Activar'} Calendario`}
        open={statusModal}
        handleClose={() => setStatusModal(false)}
        height={100}
        loading={loadingDelete}
        handleSubmit={handleStatus(2, calendarActive ? 0 : 1)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>¿Desea {calendarActive ? 'Desactivar' : 'Activar'} el Calendario {calendarId}?</p>
        </div>
      </NewModal>

    </Contenedor>
  );
}
