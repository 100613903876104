import React from 'react';
import './index.css';
import CloseIcon from "@material-ui/icons/Close"

export default function PageTitleButton(props){
    return(
        <div className="contenedor-franja-header">
            <p className='view-title' id="view-title-action">{props.title}
                <props.FiltrosComparadores/>
            </p>
            <a className='view-title' id="accion-clear" onClick={props.clearAll}><CloseIcon/></a>
            <a className='view-title' id="accion-comparar" onClick={props.action}>Comparador +</a>
            
        </div>
    )
}