import React from "react";
import "./style.css";
import Logo from "../../images/logo_uvm.png";
import Safekey from "../../images/boton_safekey.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "../../Utils/Visuales/Footer";

export default function LoginPage(props) {

  const handleClick = () => {
    console.log('[LoginPage|handleClick]')
    props.login()
  }

  return (
    <div className='LoginPage'>
      <div className='LoginBox'>
        <img src={Logo} alt='Logo' />
        {props.loading ? (
          <div style={{ marginTop: "30px" }}>
            <CircularProgress size={60} />
          </div>
        ) : (
          <form class='form' id='login'>
            <div class='button-container'>
              <button
                id='submit-btn'
                type='submit'
                form='login'
                class='button'
                onClick={handleClick}
              >
                <img
                  src={Safekey}
                  alt='boton_safekey'
                  style={{ marginBottom: 0 }}
                />
              </button>
            </div>
          </form>
        )}
      </div>
      <Footer version='0.0.1' />
    </div>
  )
}
