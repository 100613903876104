import React from "react";

const DashboardIcon = props => (
  <svg viewBox="0 0 32.22 30" {...props}>
    <defs>
      <style>{".DashboardIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="DashboardIcon_svg__Layer_2" data-name="Layer 2">
      <g id="DashboardIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="DashboardIcon_svg__cls-1"
          d="M31.68 13.56l-4.89-4.89V3a1.85 1.85 0 00-3.69 0v2l-3.64-3.65a4.86 4.86 0 00-6.72 0L.54 13.56a1.85 1.85 0 002.61 2.61L15.36 4a1.09 1.09 0 011.49 0l12.21 12.17a1.85 1.85 0 102.61-2.61z"
        />
        <path
          className="DashboardIcon_svg__cls-1"
          d="M16.75 7.47a.91.91 0 00-1.28 0L4.73 18.2a.91.91 0 00-.27.64v7.83A3.33 3.33 0 007.79 30h5.32v-8.23h6V30h5.32a3.33 3.33 0 003.33-3.33v-7.83a.91.91 0 00-.27-.64z"
        />
      </g>
    </g>
  </svg>
);

export default DashboardIcon;
