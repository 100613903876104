import React from 'react'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import './style.css'

export default function NewModal({
  open,
  title,
  handleClose,
  height,
  loading,
  children,
  noFooter,
  canDelete,
  noSaveBtn,
  active,
  handleActive,
  handleSubmit
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ zIndex: 9999999 }}
      disableBackdropClick
    >
      <div className='NewModal'>
        <CloseIcon onClick={handleClose} />
        <h3>{title}</h3>
        <div className='NewModal-container' style={{ height: height }}>
          {loading ?
            <div style={{ textAlign: 'center', paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div> :
            <>
              {children}
              {noFooter ?
                null :
                <div className='NewModal-footer'>
                  {!canDelete ? null
                    : <button
                      onClick={canDelete}
                      className='delete-button'
                    > Eliminar
                    </button>
                  }

                  <button className='gray-btn' onClick={handleClose}>
                    {noSaveBtn ? 'Cerrar' : 'Cancelar'}
                  </button>

                  {noSaveBtn ? null
                    : <button
                      className='black-btn'
                      onClick={handleSubmit}
                    > Aceptar
                    </button>
                  }

                  {!handleActive ? null
                    : <button
                      className={[active ? 'btn-danger pos-abs-r' : 'btn-success pos-abs-r']}
                      onClick={handleActive}
                    > {active ? 'Desactivar' : 'Activar'}
                    </button>
                  }
                </div>
              }
            </>
          }
        </div>
      </div>
    </Modal>
  )
}