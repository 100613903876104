import React from "react";

const UsuariosIcon = props => (
  <svg viewBox="0 0 25.03 30" {...props}>
    <defs>
      <style>{".UsuariosIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="UsuariosIcon_svg__Layer_2" data-name="Layer 2">
      <g id="UsuariosIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="UsuariosIcon_svg__cls-1"
          d="M12.33 14.45a7 7 0 005.11-2.12 7 7 0 002.12-5.11 7 7 0 00-2.12-5.11A7 7 0 0012.33 0a7 7 0 00-5.11 2.12 7 7 0 00-2.12 5.1 7 7 0 002.12 5.11 7 7 0 005.11 2.12zM25 23.07a18 18 0 00-.24-1.9 14.93 14.93 0 00-.47-1.91 9.37 9.37 0 00-.78-1.78 6.67 6.67 0 00-1.18-1.54 5.2 5.2 0 00-1.7-1.07 5.87 5.87 0 00-2.17-.39 2.2 2.2 0 00-1.17.5l-1.29.8a7 7 0 01-1.58.7 6.14 6.14 0 01-3.87 0 7 7 0 01-1.55-.7L7.77 15a2.19 2.19 0 00-1.17-.5 5.86 5.86 0 00-2.17.39 5.21 5.21 0 00-1.7 1.07 6.71 6.71 0 00-1.18 1.54 9.47 9.47 0 00-.78 1.78 15 15 0 00-.47 1.89 17.55 17.55 0 00-.24 1.9C0 23.64 0 24.24 0 24.84a5 5 0 001.48 3.77A5.32 5.32 0 005.29 30h14.45a5.33 5.33 0 003.81-1.39A5 5 0 0025 24.84v-1.77z"
        />
      </g>
    </g>
  </svg>
);

export default UsuariosIcon;
