import React, { useState, useEffect, useRef, useCallback } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import './index.css'
import NewButton from '../../Utils/Botones/NewButton'
import EditButton from '../../Utils/Botones/EditButton'
import DataTable from '../../Utils/DataTables'
import SearchInput from '../../Utils/Visuales/SearchInput'
import NewModal from '../../Utils/Visuales/NewModal'
import Grid from '@material-ui/core/Grid'
import EditAvatar from '../../images/EditAvatar'
import Can from '../../Utils/Funciones/can'
import { STYLES } from '../../Utils/variables'
import { getRequest, postRequest, putRequest } from '../../Utils/Funciones/requester'
import SelectSearch from 'react-select-search'
import Select from 'react-select'
import avatarFemenino from '../../images/femenino.png'
import avatarMasculino from '../../images/masculino.png'
import PrefieroNoDecirlo from '../../images/pnd.png'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useSnackbar } from 'notistack'

export default function Usuarios(props) {
  const [dataOriginal, setDataOriginal] = useState([])
  const [dataFinal, setDataFinal] = useState([])
  const [nuevoUsuarioModal, setNuevoUsuarioModal] = useState(false)
  const [editarUsuarioModal, setEditarUsuarioModal] = useState(false)
  const [changeActiveModal, setChangeActiveModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingModalEditar, setLoadingModalEditar] = useState(false)
  const [opcionesRol, setOpcionesRol] = useState([])
  const headers = [
    { col: 0, name: 'Clave' },
    { col: 1, name: 'Nombre' },
    { col: 2, name: 'Rol' },
    { col: 3, name: 'Estatus' },
    { col: 4, name: 'Acciones' }
  ]
  const generos = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Femenino', label: 'Femenino' },
    { value: 'Prefiero no decirlo', label: 'Prefiero no decirlo' },
    { value: 'Otro', label: 'Otro' }
  ]
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMsg, setSnackMsg] = useState('')
  const [snackType, setSnackType] = useState('')
  const fileNew = useRef(null)
  const fileEdit = useRef(null)
  const [fileDelete, setFileDelete] = useState(false)
  const [refresh, setRefresh] = useState(0)

  // Crear y editar usuario
  const [id, setId] = useState('')
  const [username, setUsername] = useState('')
  const [clave, setClave] = useState('')
  const [genero, setGenero] = useState('')
  const [nombre, setNombre] = useState('')
  const [aPaterno, setAPaterno] = useState('')
  const [aMaterno, setAMaterno] = useState('')
  const [rol, setRol] = useState('')
  const [correo, setCorreo] = useState('')
  const [fijo, setFijo] = useState('')
  const [movil, setMovil] = useState('')
  const [avatar, setAvatar] = useState('')
  const [img, setImg] = useState('')
  const [recibirConcentrado, setRecibirConcentrado] = useState(true)
  const [active, setActive] = useState(1)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  async function openEditModal(id) {
    setEditarUsuarioModal(true)
    setLoadingModalEditar(true)
    let res = await getRequest('/rol')
    if (res.s === 'OK') {
      let data = []
      res.d.forEach(({ nombre }) => data.push({ label: nombre, value: nombre }))
      setOpcionesRol(data)
    } else {
      enqueueSnackbar(res.m, STYLES.failure)
    }

    res = await getRequest(`/usuario/detalle?id=${id}`)
    if (res.s === 'OK') {
      setId(res.d.id)
      setClave(res.d.clave)
      setNombre(res.d.nombre)
      setAPaterno(res.d.apellido_paterno)
      setAMaterno(res.d.apellido_materno)
      setCorreo(res.d.email)
      setMovil(res.d.movil)
      setFijo(res.d.fijo)
      setImg(res.d.img_perfil)
      setGenero({
        value: res.d.genero,
        label: res.d.genero
      })
      setUsername(res.d.usuario)
      setRol(res.d.rol.map(item => ({
        value: item,
        label: item
      })))

      setActive(res.d.activo ? 1 : 0)
      setLoadingModalEditar(false)
      setRecibirConcentrado(res.d.recepcion_concentrado)
    } else {
      setEditarUsuarioModal(false)
      setSnackMsg(res.m)
      setSnackType('error')
      setSnackOpen(true)
    }
  }

  useEffect(() => {
    setClave('')
    setGenero('')
    setNombre('')
    setAPaterno('')
    setAMaterno('')
    setRol('')
    setCorreo('')
    setFijo('')
    setMovil('')
    setAvatar('')
    setImg('')
    setRecibirConcentrado(false)
  }, [nuevoUsuarioModal])

  useEffect(() => {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    async function fetchData() {
      try {
        setLoading(true)
        let res = await getRequest('/usuario', pageSignal)
        if (res.s === 'OK') {
          let data = []
          res.d.forEach(u => {
            data.push([
              u.clave,
              u.nombre,
              u.rol.join(', '),
              u.estatus ? 'Activo' : 'Inactivo',
              <Can I='edit' a='usuarios'>
                <EditButton onClick={() => openEditModal(u.id)}>
                  Editar
                </EditButton>
              </Can>
            ])
          })
          setDataOriginal(data)
          setDataFinal(data)
          setLoading(false)
        } else {
          enqueueSnackbar(res.m, STYLES.failure)
        }
      } catch (error) { console.log({ error }) }
    }
    fetchData()
    return function cleanup() {
      pageController.abort()
    }
  }, [refresh])

  async function handleOpenModalNuevoUsuario() {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    setNuevoUsuarioModal(true)
    setLoadingModal(true)
    setId('')
    setClave('')
    setGenero('')
    setNombre('')
    setAPaterno('')
    setAMaterno('')
    setRol('')
    setCorreo('')
    setFijo('')
    setMovil('')
    setAvatar('')
    setImg('')
    let res = await getRequest('/rol')
    if (res.s === 'OK') {
      let data = []
      res.d.forEach(({ nombre }) => {
        data.push({ label: nombre, value: nombre })
      })
      setOpcionesRol(data)
    } else {
      enqueueSnackbar(res.m, STYLES.failure)
    }
    setLoadingModal(false)
  }

  function search(text) {
    text = text.toLowerCase()
    let result = dataOriginal.filter(row => {
      if (row[0].toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      else if (row[2].toLowerCase().includes(text)) return true
      return false
    })
    setDataFinal(result)
  }

  function getAvatarNew() {
    if (img !== '') return <img src={img} className='avatar' alt='Avatar de usuario' />

    let componente
    switch (genero.value) {
      case 'Femenino':
        componente = <img src={avatarFemenino} className='avatar' alt='Avatar de usuario' />
        break
      case 'Masculino':
        componente = <img src={avatarMasculino} className='avatar' alt='Avatar de usuario' />
        break
      case 'Prefiero no decirlo':
        componente = <img src={PrefieroNoDecirlo} className='avatar' alt='Avatar de usuario' />
        break
      default:
        componente = <img src={PrefieroNoDecirlo} className='avatar' alt='Avatar de usuario' />
        break
    }
    return componente
  }

  function getAvatarEdit() {
    if (img !== '') return <img src={img} className='avatar' alt='Avatar de usuario' />

    let componente
    switch (genero.value) {
      case 'Femenino':
        componente = <img src={avatarFemenino} className='avatar' alt='Avatar de usuario' />
        break
      case 'Masculino':
        componente = <img src={avatarMasculino} className='avatar' alt='Avatar de usuario' />
        break
      case 'Prefiero no decirlo':
        componente = <img src={PrefieroNoDecirlo} className='avatar' alt='Avatar de usuario' />
        break
      default:
        componente = <img src={PrefieroNoDecirlo} className='avatar' alt='Avatar de usuario' />
        break
    }
    return componente
  }

  async function sendEditUser(newActive = 1) {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    let valido = true

    if (clave === '') {
      document.getElementsByClassName('input-clave')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-clave')[0].classList.remove('error-input')
    }

    if (genero === '') {
      document.getElementsByClassName('input-genero')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-genero')[0].classList.remove('error-input')
    }

    if (nombre === '') {
      document.getElementsByClassName('input-nombre')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-nombre')[0].classList.remove('error-input')
    }

    if (rol === '') {
      document.getElementsByClassName('input-rol')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-rol')[0].classList.remove('error-input')
    }

    if (!re.test(correo)) {
      document.getElementsByClassName('input-correo')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-correo')[0].classList.remove('error-input')
    }

    if (valido) {
      let json = {
        id_usuario: id,
        nombre: nombre,
        apaterno: '',
        amaterno: '',
        email: correo,
        movil: movil,
        fijo: fijo,
        sexo: genero.value,
        roles: rol.map(item => (item.value)),
        activo: newActive,
        recepcion_concentrado: recibirConcentrado
      }

      setLoadingModalEditar(true)
      let res = await postRequest('/usuario/editar', json)
      if (res.s === 'OK') {
        if (avatar !== '') {
          if (fileDelete) {
            json = {
              id_usuario: id
            }
            let resDelete = await postRequest('/usuario/imagen/eliminar', json)
            json = {
              id_usuario: id,
              nombre: avatar.name
            }
            let resImg = await postRequest('/usuario/imagen/subir', json)
            if (resImg.s === 'OK') {
              await putRequest(resImg.d.preSignedString, avatar)
            }
          } else {
            json = {
              id_usuario: id,
              nombre: avatar.name
            }
            let resImg = await postRequest('/usuario/imagen/subir', json)
            if (resImg.s === 'OK') {
              await putRequest(resImg.d.preSignedString, avatar)
            }
          }
        }
        setLoadingModalEditar(false)
        setEditarUsuarioModal(false)
        enqueueSnackbar('Usuario editado correctamente', STYLES.success)
        setRefresh(refresh + 1)
      } else {
        enqueueSnackbar(res.m, STYLES.failure)
      }
    } else {
      setSnackMsg('Debe rellenar los campos correctamente.')
      setSnackType('error')
      setSnackOpen(true)
    }
  }

  function changeNewImage(event) {
    if (avatar === '') setFileDelete(true)

    setAvatar(event.target.files[0])
    var reader = new FileReader()

    reader.onload = function (e) {
      setImg(e.target.result)
    }

    reader.readAsDataURL(event.target.files[0])
  }

  async function sendNewUser() {
    let valido = true
    const pageController = new AbortController()
    const pageSignal = pageController.signal

    if (clave === '') {
      document.getElementsByClassName('input-clave')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-clave')[0].classList.remove('error-input')
    }

    if (genero === '') {
      document.getElementsByClassName('input-genero')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-genero')[0].classList.remove('error-input')
    }

    if (nombre === '') {
      document.getElementsByClassName('input-nombre')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-nombre')[0].classList.remove('error-input')
    }

    if (rol === '') {
      document.getElementsByClassName('input-rol')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-rol')[0].classList.remove('error-input')
    }

    if (!re.test(correo)) {
      document.getElementsByClassName('input-correo')[0].classList.add('error-input')
      valido = false
    } else {
      document.getElementsByClassName('input-correo')[0].classList.remove('error-input')
    }

    if (valido) {
      let json = {
        nss: clave,
        nombre: nombre,
        apaterno: '',
        amaterno: '',
        email: correo,
        movil: movil,
        fijo: fijo,
        sexo: genero.value,
        user: correo,
        passwd: clave,
        roles: rol.map(item => (item.value)),
        recepcion_concentrado: recibirConcentrado
      }
      setLoadingModal(true)
      let response = await postRequest('/usuario/nuevo', json)
      setLoadingModal(false)
      if (response.s === 'OK') {
        enqueueSnackbar('Usuario creado correctamente.', STYLES.success)
        setNuevoUsuarioModal(false)
        setRefresh(refresh + 1)
        if (avatar !== '') {
          json = {
            id_usuario: response.d,
            nombre: avatar.name
          }
          let responseImg = await postRequest('/usuario/imagen/subir', json)
          if (responseImg.s === 'OK') {
            await putRequest(responseImg.d.preSignedString, avatar)
          }
        }
      } else {
        enqueueSnackbar(response.m, STYLES.failure)
      }
    } else {
      setSnackMsg('Debe rellenar los campos correctamente.')
      setSnackType('error')
      setSnackOpen(true)
    }
  }

  const handleActive = option => () => {
    if (option === 1) {
      setChangeActiveModal(true)
    } else if (option === 2) {
      // setActive(1)
      setChangeActiveModal(false)
      sendEditUser(active ? 0 : 1)
    }
  }

  return (
    <Contenedor title='Usuarios'>
      <div className='opciones-bar'>
        <div style={{ float: 'left' }}>
          <Can I='create' a='usuarios'>
            <NewButton onClick={handleOpenModalNuevoUsuario} />
          </Can>
        </div>
        <div style={{ float: 'right' }}>
          <SearchInput search={search} />
        </div>
      </div>

      <div style={{ height: 'calc(100% - 65px)' }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[3, 4]}
          paginate
        />
      </div>

      {/* Editar Usuario */}
      <NewModal
        title='Editar Usuario'
        open={editarUsuarioModal}
        handleClose={() => setEditarUsuarioModal(false)}
        height={430}
        loading={loadingModalEditar}
        active={active}
        handleActive={handleActive(1)}
        handleSubmit={() => sendEditUser()}
      >
        <div className='nuevoUsuarioForm-edit'>
          <div className='user-layout'>
            <div className='avatarForm'>
              {getAvatarEdit()}
              <EditAvatar className='editAvatar' onClick={() => fileEdit.current.click()} />
              <input
                ref={fileEdit}
                style={{ display: 'none' }}
                type='file'
                onChange={(e) => changeNewImage(e)}
                disabled={!active}
              />
            </div>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <label>Número de Empleado (*)</label>
                  <input
                    className='input-clave'
                    placeholder='Ingresa Número de Empleado'
                    value={clave}
                    onChange={e => setClave(e.target.value)}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Usuario (*)</label>
                  <input
                    className='input-clave'
                    placeholder='Ingrese usuario'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Nombre (*)</label>
                  <input
                    className='input-nombre'
                    placeholder='Ingresa Nombre'
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                    disabled={!active}
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Correo electrónico (*)</label>
                  <input
                    className='input-correo'
                    placeholder='Ingresa Correo'
                    value={correo}
                    onChange={e => setCorreo(e.target.value)}
                    disabled={!active}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label>Rol (*)</label>
              <Select
                options={opcionesRol}
                placeholder='Seleccione rol...'
                className='select-modal input-rol'
                classNamePrefix='select-modal'
                value={rol}
                onChange={setRol}
                isMulti
                isDisabled={!active}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Género (*)</label>
              <Select
                options={generos}
                placeholder='Seleccione genero...'
                className='select-modal input-genero'
                classNamePrefix='select-modal'
                value={genero}
                onChange={setGenero}
                isDisabled={!active}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Teléfono Fijo:</label>
              <input
                className='input-fijo'
                placeholder='Ingrese teléfono fijo...'
                value={fijo}
                onChange={e => setFijo(e.target.value)}
                disabled={!active}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Teléfono Móvil:</label>
              <input
                className='input-movil'
                placeholder='Ingrese teléfono móvil...'
                value={movil}
                onChange={e => setMovil(e.target.value)}
                disabled={!active}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p style={{ margin: '8px 5px 0 0', textAlign: 'right', fontSize: '16px', fontWeight: 800 }}>Recibir concentrado:</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    style={{ padding: 0 }}
                    checked={recibirConcentrado}
                    disabled={!active}
                  />
                }
                onChange={e => setRecibirConcentrado(e.target.checked)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>

      {/* Nuevo Usuario */}
      <NewModal
        title='Nuevo Usuario'
        open={nuevoUsuarioModal}
        handleClose={() => setNuevoUsuarioModal(false)}
        height={430}
        loading={loadingModal}
        handleSubmit={sendNewUser}
      >
        <div className='nuevoUsuarioForm-edit'>
          <div className='user-layout'>
            <div className='avatarForm'>
              {getAvatarNew()}
              <EditAvatar className='editAvatar' onClick={() => fileNew.current.click()} />
              <input ref={fileNew} style={{ display: 'none' }} type='file' onChange={(e) => changeNewImage(e)} />
            </div>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <label>Número de Empleado (*)</label>
                  <input
                    type='number'
                    placeholder='Ingresa Número de Empleado'
                    value={clave}
                    onChange={e => setClave(e.target.value)}
                    className='input-clave'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Usuario (*)</label>
                  <input placeholder='Ingresa Usuario' value={correo} disabled />
                </Grid>
                <Grid item xs={12}>
                  <label>Nombre (*)</label>
                  <input
                    placeholder='Ingresa Nombre'
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                    className='input-nombre'
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Correo electrónico (*)</label>
                  <input
                    placeholder='Ingresa Correo'
                    value={correo}
                    onChange={e => setCorreo(e.target.value)}
                    className='input-correo'
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label>Rol (*)</label>
              <Select
                options={opcionesRol}
                placeholder='Seleccione rol...'
                className='select-modal input-rol'
                classNamePrefix='select-modal'
                value={rol}
                onChange={setRol}
                isMulti
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Género (*)</label>
              <Select
                options={generos}
                placeholder='Seleccione genero...'
                className='select-modal input-genero'
                classNamePrefix='select-modal'
                value={genero}
                onChange={setGenero}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Teléfono Fijo:</label>
              <input
                placeholder='Ingresa Teléfono Fijo...'
                value={fijo}
                onChange={e => setFijo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Teléfono Móvil:</label>
              <input
                placeholder='Ingresa Teléfono Móvil...'
                value={movil}
                onChange={e => setMovil(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p style={{ margin: '8px 5px 0 0', textAlign: 'right', fontSize: '16px', fontWeight: 800 }}>Recibir concentrado:</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox color='primary' style={{ padding: 0 }} checked={recibirConcentrado} />
                }
                onChange={e => setRecibirConcentrado(e.target.checked)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>

      {/* Cambio de Status */}
      <NewModal
        title={`${active ? 'Desactivar' : 'Activar'} Usuario`}
        open={changeActiveModal}
        handleClose={() => setChangeActiveModal(false)}
        height={100}
        loading={loadingModal}
        handleSubmit={handleActive(2)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>¿Desea {active ? 'Desactivar' : 'Activar'} el usuario de {nombre}?</p>
        </div>
      </NewModal>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key='top,right'
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        autoHideDuration={2000}
      >
        <MuiAlert severity={snackType}>
          {snackMsg}
        </MuiAlert>
      </Snackbar>
    </Contenedor>
  )
}