import React from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'

export default function MasivaModal(props){
    
    return(
        <Modal open={props.open} onClose={props.handleClose} disableBackdropClick>
            <div className='NewModal'>
                <CloseIcon onClick={props.handleClose} />
                <h3>{props.title}</h3>
                <div className='NewModal-container' style={{height: props.height}}>
                    {
                        props.loading ?
                        <div style={{textAlign: 'center', paddingTop: 30}}>
                            <CircularProgress size={50} />
                        </div> :
                        <>
                            {props.children}
                            <div className='NewModal-footer'>
                                <button className='gray-btn' onClick={props.handleClose}>Cancelar</button>
                                <button className='black-btn' disabled={props.disabled} style={props.disabled ? {opacity: '.25'} : null} onClick={props.handleSubmit}>Procesar</button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}