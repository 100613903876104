import React from 'react'
import './style.css'
import { version } from '../../Funciones/requester'
export default function Footer(props){
    return(
        <footer>
            <span>V{version}</span>
            <span>Powered by Jalisoft S de RL de CV</span>
        </footer>
    )
}