import React from 'react';
import './style.css';

export default function BlackButton(props){
    return(
        <button className={props.disabled ? 'BlackButton ButtonCancel' : 'BlackButton'} onClick={props.onClick} style={props.style} disabled={props.disabled}>
            {props.icon}
            <span>{props.children}</span>
        </button>
    )
}