import React, { createContext, useState } from 'react'

const ConcentradoContext = createContext(null)

function ConcentradoProvider(props){
    const [cycle, setCycle] = useState(null)
    const [level, setLevel] = useState(null)
    const [docente, setDocente] = useState(null)
    const [claveDocente, setClaveDocente] = useState(null)

    function setState(newCycle, newLevel, newDocente, newClaveDocente){
        setCycle(newCycle)
        setLevel(newLevel)
        setDocente(newDocente)
        setClaveDocente(newClaveDocente)
    }

    const value = {
        state: {
            cycle,
            level,
            docente,
            claveDocente
        },
        actions: {
            setState
        }
    }

    return (
        <ConcentradoContext.Provider value={value}>
            {props.children}
        </ConcentradoContext.Provider>
    )
}

export default ConcentradoContext
export { ConcentradoProvider }