import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MemoryRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';
import {NivelesProvider} from './Utils/Providers/NivelesProvider';

ReactDOM.render(<SnackbarProvider maxSnack={5} preventDuplicate autoHideDuration={2000}><NivelesProvider>
    <Router><App /></Router>
</NivelesProvider></SnackbarProvider>, document.getElementById('root'));