import React from 'react';
import './index.css';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Infobox(props){
    return(
        <div className='infobox'>
            {
                props.loading ?
                <div style={{paddingTop: '10px'}}>
                    <CircularProgress />
                </div> :
                <>
                    {props.icon}
                    <div>
                        <p className='infobox-title'>{props.title}</p>
                        <div className='infobox-total'>{props.total}</div>
                    </div>
                </>
            }
        </div>
    )
}