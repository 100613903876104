import React, { useState, useEffect, useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'
import LoginPage from './Vistas/LoginPage'
import Header from './Utils/Visuales/Header'
import Menu from './Utils/Menu'
import Dashboard from './Vistas/Dashboard'
import Usuarios from './Vistas/Usuarios'
import Docentes from './Vistas/Docentes'
import Criterios from './Vistas/Criterios'
import Envio from './Vistas/Envio'
import Coordinadores from './Vistas/Coordinadores'
import Calendario from './Vistas/Calendario'
import { getRequest } from './Utils/Funciones/requester'
import { ability, setPermisos } from './Utils/Funciones/ability'
import Historial from './Vistas/Historial'
import ReportesLista from './Vistas/Reportes/ReportesLista'
import ReportesConcentrado from './Vistas/Reportes/ReportesConcentrado'
import ReportesCriterios from './Vistas/Reportes/ReportesCriterios'
import ReportesAsignacion from './Vistas/Reportes/ReportesAsignacion'
import { ListaVerificacionProvider } from './Utils/Providers/ListaVerificacionProvider'
import { ConcentradoProvider } from './Utils/Providers/ConcentradoProvider'
import { CursoProvider } from './Utils/Providers/CursoProvider'
import NivelesContext from './Utils/Providers/NivelesProvider'
import { useSnackbar } from 'notistack'
import { STYLES } from './Utils/variables'
import config from './config'
import './App.css'

export default function App() {
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [logged, setLogged] = useState(false)
  const [logging, setLogging] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [nombre, setNombre] = useState('Usuario')
  const [userRol, setUserRol] = useState('Rol')
  const [image, setImage] = useState('')
  let history = useHistory()

  const context = useContext(NivelesContext)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  async function login() {
    setErrorMsg('')
    setLogging(true)
    try {
      if (config.isDev) {
        localStorage.setItem('token_tv', '20231006200044720715')
        return window.location.reload()
      }

      const data = await getRequest(
        '?plt=silver&token=&rol=administrador',
        null,
        false,
        true
      )
      if (data.s === 'OK') {
        window.location.href = data['d']['data']['url_login']
      } else {
        setLogging(false)
        setErrorMsg(data.m)
      }
    } catch (error) {
      console.error(error)
      setLogging(false)
    }
  }

  useEffect(() => {
    checkLogin()
  }, [])

  async function checkLogin() {
    let token = localStorage.getItem('token_tv')
    console.log('[checkLogin]', { token })

    if (token) {
      let myUrl = window.location.href
      let inicioParametros = myUrl.indexOf('?')
      let newUrl = myUrl.substr(0, inicioParametros)

      window.history.pushState('obj', 'newtitle', newUrl)
      setLogging(true)
      let res = await getRequest('/login/detalle')
      if (res && res.s === 'OK') {
        let resNiveles = await getRequest('/nivel')
        if (resNiveles.s === 'OK') {
          if (resNiveles.d.length > 0) {
            let data = []
            resNiveles.d.forEach((nivel) => {
              let totalSemanas = 1
              nivel.calendarios.forEach((modulo) => {
                if (modulo.total_semanas > totalSemanas) {
                  totalSemanas = modulo.total_semanas
                }
              })
              data.push({
                label: nivel.nombre,
                value: nivel.nombre,
                semanas: totalSemanas,
              })
            })
            context.actions.setNiveles(data)
          } else {
            enqueueSnackbar(resNiveles.m, STYLES.failure)
            context.actions.setNiveles([])
          }
        } else {
          enqueueSnackbar(res.m, STYLES.failure)
        }

        ability.update(setPermisos(res.d.permisos))
        setLogging(false)
        setLogged(true)
        setNombre(res.d.nombre)
        setUserRol(res.d.rol)
        setImage(res.d.url_img)
        history.push('/dashboard')
      } else {
        setLogging(false)
      }
    } else {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const code = urlParams.get('code')
      if (code !== null) {
        setLogging(true)
        try {
          const data = await getRequest(
            `?plt=silver&code=${code}&rol=administrador`,
            null,
            false,
            true
          )

          if (data.s === 'OK') {
            window.localStorage.setItem('token_tv', data['d']['data']['token'])
            window.location.reload()
          } else {
            enqueueSnackbar(data.m, STYLES.failure)
            window.localStorage.removeItem('token_tv')
            let myUrl = window.location.href
            let inicioParametros = myUrl.indexOf('?')
            let newUrl = myUrl.substr(0, inicioParametros)
            window.history.pushState('obj', 'newtitle', newUrl)
            setLogging(false)
          }
        } catch (error) {
          console.log(error)
          let myUrl = window.location.href
          let inicioParametros = myUrl.indexOf('?')
          let newUrl = myUrl.substr(0, inicioParametros)

          window.history.pushState('obj', 'newtitle', newUrl)
          setTimeout(() => {
            window.location.reload()
          }, 2000)

          window.localStorage.removeItem('token_tv')
          setLogging(false)
        }
      }
    }
  }

  return (
    <>
      {logged ? (
        <ListaVerificacionProvider>
          <ConcentradoProvider>
            <CursoProvider>
              <Header username={nombre} rol={userRol} image={image} />
              <div className='container-main'>
                <Menu />
                <Route path='/dashboard'>
                  <Dashboard />
                </Route>
                <Route path='/usuarios'>
                  <Usuarios />
                </Route>
                <Route path='/docentes'>
                  <Docentes />
                </Route>
                <Route path='/coordinadores'>
                  <Coordinadores />
                </Route>
                <Route path='/calendario'>
                  <Calendario />
                </Route>
                <Route path='/criterios'>
                  <Criterios />
                </Route>
                <Route path='/historial'>
                  <Historial />
                </Route>
                <Route path='/envio'>
                  <Envio />
                </Route>
                <Route path='/reportes/lista'>
                  <ReportesLista />
                </Route>
                <Route path='/reportes/concentrado'>
                  <ReportesConcentrado />
                </Route>
                <Route path='/reportes/criterios'>
                  <ReportesCriterios />
                </Route>
                <Route path='/reportes/curso'>
                  <ReportesAsignacion />
                </Route>
              </div>
            </CursoProvider>
          </ConcentradoProvider>
        </ListaVerificacionProvider>
      ) : (
        <LoginPage
          login={login}
          user={username}
          error={errorMsg}
          userError={usernameError}
          passError={passwordError}
          setUser={setUsername}
          setPass={setPassword}
          loading={logging}
        />
      )}
    </>
  )
}
