import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import './style.css';

export default function NewButton(props){
    return(
        <button className='NewButton' onClick={props.onClick}>
            <AddCircleIcon />
            <span>Nuevo</span>
        </button>
    )
}