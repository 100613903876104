import React from "react";

const EnvioIcon = props => (
  <svg viewBox="0 0 30 30" {...props}>
    <defs>
      <style>{".EnvioIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="EnvioIcon_svg__Layer_2" data-name="Layer 2">
      <g id="EnvioIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="EnvioIcon_svg__cls-1"
          d="M4.45 14.06a2.62 2.62 0 001.83-.74l2.6 1.3a2.58 2.58 0 000 .32 2.64 2.64 0 005.27 0 2.6 2.6 0 00-.26-1.13l3.53-3.53a2.6 2.6 0 001.13.26 2.64 2.64 0 002.64-2.64 2.6 2.6 0 00-.19-.77l3.07-2.3a2.63 2.63 0 10-1.18-2.19 2.6 2.6 0 00.13.78L20 5.72A2.63 2.63 0 0016.14 9l-3.53 3.53a2.59 2.59 0 00-3 .48l-2.6-1.3a2.58 2.58 0 000-.32 2.64 2.64 0 10-2.64 2.64z"
        />
        <path
          className="EnvioIcon_svg__cls-1"
          d="M29.12 28.24h-.94V9.67a.88.88 0 00-.88-.88h-3.51a.88.88 0 00-.88.88v18.57h-1.76v-13.3a.88.88 0 00-.88-.88h-3.51a.88.88 0 00-.88.88v13.3h-1.76V22a.88.88 0 00-.88-.88H9.73a.88.88 0 00-.88.88v6.27H7.09v-9.81a.88.88 0 00-.88-.88H2.7a.88.88 0 00-.88.88v9.79H.88a.88.88 0 000 1.75h28.24a.88.88 0 000-1.76z"
        />
      </g>
    </g>
  </svg>
);

export default EnvioIcon;
