import React, { useState, useRef } from 'react';

export default function ListaEditable(props){
    const [isSelectedRow, setIsSelectedRow] = useState(false);
    const criteriosScroll = useRef(null);
    const weeksScroll = useRef(null);
    const editScroll = useRef(null);
    const [editable,setEditable] = useState(-1)

    function handleClick(e,claveCriterio,indexSemana){
        /*
        if(e.target.classList.contains('selected')){
            e.target.classList.remove('selected');
        } else {
            e.target.classList.add('selected');
        }
        if(document.getElementsByClassName('focus')[0]){
            document.getElementsByClassName('focus')[0].classList.remove('focus');
        }*/
        let misSemanas=[]
        props.criterios.forEach(criterio=>{
            let misIndividuales=[]
            criterio.weeks.forEach(semana=>{
                misIndividuales.push(semana)
            })
            misSemanas.push({
                clave:criterio.clave,
                name:criterio.name,
                weeks:misIndividuales
            })
        })
        if(misSemanas[claveCriterio].weeks[indexSemana] == 'N/A'){
            misSemanas[claveCriterio].weeks[indexSemana] = ''
        }else if(misSemanas[claveCriterio].weeks[indexSemana] == ''){
            misSemanas[claveCriterio].weeks[indexSemana] = 'X'
        }else{
            misSemanas[claveCriterio].weeks[indexSemana] = 'N/A'
        }

        props.setCalendarWeeks(misSemanas)
    }

    function getWeeksHeaders(){
        let headers = [];
        for(let i = 0; i < props.weeks.length; i++){
            headers.push(
                <div className='Calendar-Week Cell-Min-Width vinotinto'>
                    {props.weeks[i]}
                </div>
            );
        }
        return headers;
    }

    function getEditHeaders(){
        let headers = [];
        for(let i = 0; i < props.weeks.length; i++){
            headers.push(
                <div 
                    onClick={()=>{
                        if(i===editable){
                            props.editarSemana(i)
                            return
                        }
                        if(i<props.maxWeek){
                            setEditable(i)
                        }
                    }}
                    className={i < props.maxWeek ? 'Calendar-Week Cell-Min-Width negro' : 'Calendar-Week Cell-Min-Width blanco-ocultar'}
                >
                    {
                        i==editable ?
                        'Generar'
                        :
                        'Editar'
                    }
                </div>
            );
        }
        return headers;
    }

    function onScroll(e){
        let x = e.target.scrollLeft;
        let y = e.target.scrollTop;
        criteriosScroll.current.scrollTop = y;
        weeksScroll.current.scrollLeft = x;
        editScroll.current.scrollLeft= x;
    }

    return(
        <>
            <div className='Calendar-Headers' style={{gridTemplateColumns: `50px 200px`, width: '250px', float: 'left'}}>
                <div style={{position:'relative',color:'white'}}>a</div>
                <div style={{position:'relative'}}></div>
            </div>
            <div ref={editScroll} className='Calendar-Headers' style={{gridTemplateColumns: `repeat(${props.weeks.length}, 1fr)`, marginRight: '16px'}}>
                {
                    getEditHeaders()
                }
            </div>

            <div className='Calendar-Headers' style={{gridTemplateColumns: `50px 200px`, width: '250px', float: 'left'}}>
                <div className='Calendar-Week grey-header headers-lista-editar'>Clave</div>
                <div className='Calendar-Week grey-header headers-lista-editar'>Descripción</div>
            </div>

            <div ref={weeksScroll} className='Calendar-Headers' style={{gridTemplateColumns: `repeat(${props.weeks.length}, 1fr)`, marginRight: '16px'}}>
                {
                    getWeeksHeaders()
                }
            </div>
            <div className='Calendar-Container-Full'>
                <div ref={criteriosScroll} className='Calendar-Criterios'>
                    {
                        props.criterios.map(row => (
                            [<div className='Calendar-Criterio criterio-blanco'>{row.clave}</div>,<div className='Calendar-Criterio criterio-blanco'>{row.name}</div>]
                        ))
                    }
                </div>
                <div onScroll={onScroll} className='Calendar-Cells' style={{gridTemplateColumns: `repeat(${props.weeks.length}, 1fr)`, gridTemplateRows: `repeat(${props.criterios.length}, 55px)`}}>
                    {
                        props.criterios.map((criterio,indexCriterio) => {
                            return criterio.weeks.map((value,indexSemana) => {
                                return <div clave={criterio.clave} draggable='true' 
                                            onClick={(e)=>{
                                                if((indexSemana + 1 <= props.maxWeek) && (indexSemana === editable)){
                                                    handleClick(e,indexCriterio,indexSemana)
                                                }
                                            }} 
                                            className={
                                                indexSemana + 1 > props.maxWeek ? 'Calendar-Cell bloqueada Cell-Min-Width' : 
                                                indexSemana !== editable && indexSemana + 1 <= props.maxWeek && value == 'N/A' ? 'Calendar-Cell bloqueada2Selected Cell-Min-Width': 
                                                indexSemana !== editable && indexSemana + 1 <= props.maxWeek ? 'Calendar-Cell bloqueada2 Cell-Min-Width': 
                                                value == 'N/A' ? 'Calendar-Cell selected Cell-Min-Width' : 
                                                'Calendar-Cell Cell-Min-Width'}
                                            >
                                    {value == 'N/A' ? 'N/A': value == 'X' ? 'X' : value == 'F' ? '' : ''} 
                                </div>
                            })
                        })
                    }
                </div>
            </div>
        </>
    )
}