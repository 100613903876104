
export const STYLES = {
  success: {
    variant: 'success',
    anchorOrigin: { vertical: 'top', horizontal: 'right' }
  },
  failure: {
    variant: 'error',
    anchorOrigin: { vertical: 'top', horizontal: 'right' }
  }
}