import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './index.css';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DataTables(props) {
  let data = props.data;
  const [orderBy, setOrderBy] = useState({ col: 0, asc: true })
  const [rowsPerPage, setRowsPerPage] = useState(data.length)
  const [pageActual, setPageActual] = useState(0)
  const [refresh, setRefresh] = useState(true);
  const tableRef = useRef(null)
  let i = 0
  let j = 0
  let centrados = props.centrar ? props.centrar : []

  useEffect(() => {
    if (orderBy.asc) {
      data.sort((a, b) => {
        a = a[orderBy.col]
        b = b[orderBy.col]

        while (typeof a === 'object') {
          a = a.props.children;
        }

        while (typeof b === 'object') {
          b = b.props.children;
        }

        if (typeof a !== 'number' && typeof b !== 'number') {
          if (a.includes('%')) {
            a = a.split('%')[0]
          }

          if (b.includes('%')) {
            b = b.split('%')[0]
          }

          if (!isNaN(a) && !isNaN(b)) {
            a = parseFloat(a)
            b = parseFloat(b)
          }
        }

        if (a === 'N/A') {
          return 1
        }

        if (b === 'N/A') {
          return -1
        }

        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        return 0

      })
    } else {
      data.sort((a, b) => {
        a = a[orderBy.col]
        b = b[orderBy.col]

        while (typeof a === 'object') {
          a = a.props.children;
        }

        while (typeof b === 'object') {
          b = b.props.children;
        }

        if (typeof a !== 'number' && typeof b !== 'number') {
          if (a.includes('%')) {
            a = a.split('%')[0]
          }

          if (b.includes('%')) {
            b = b.split('%')[0]
          }

          if (!isNaN(a) && !isNaN(b)) {
            a = parseFloat(a)
            b = parseFloat(b)
          }
        }

        if (a === 'N/A') {
          return 1
        }

        if (b === 'N/A') {
          return -1
        }

        if (a < b) {
          return 1
        }
        if (a > b) {
          return -1
        }
        return 0
      })
    }
    setRefresh(!refresh);
  }, [orderBy])

  useEffect(() => {
    if (props.paginate) {
      setPageActual(0);
      let height = tableRef.current.clientHeight;
      let width = window.innerWidth;
      const rowHeight = width < 1200 ? 48 : 30;
      const rowHeightCriterios = width < 1200 ? 51 : 33;
      height -= 35;
      let newRows;
      if (props.criterios) {
        newRows = Math.trunc(height / rowHeightCriterios);
      } else {
        newRows = Math.trunc(height / rowHeight);
      }
      setRowsPerPage(newRows)
    } else {
      setRowsPerPage(data.length)
    }
  }, [data, props.paginate])

  function getRows() {
    let rows = []
    let maxLength = pageActual < Math.ceil(data.length / rowsPerPage) - 1 ? (rowsPerPage * pageActual) + rowsPerPage : data.length;
    if (data.length > 0) {
      for (i = rowsPerPage * pageActual; i < maxLength; i++) {
        j = 0
        rows.push(
          <TableRow>
            {
              props.headers.map((h, k) => {
                j++
                return <TableCell style={props.criterios && j === 1 ? { width: '170px' } : null} align={centrados.indexOf(j) >= 0 ? 'center' : 'left'}>{data[i][k]}</TableCell>
              })
            }
          </TableRow>
        )
      }
    } else {
      return null
    }
    return rows
  }

  function getPaginate() {
    let buttons = []
    if (props.bordes) {
      buttons.push(
        <button className="pagina-inicio-btn" onClick={() => {
          setPageActual(0)
        }}>Primera</button>
      )
    }
    buttons.push(
      <button onClick={() => {
        if (pageActual > 0) {
          setPageActual(pageActual - 1)
        }
      }}>Anterior</button>
    )
    if (Math.ceil(data.length / rowsPerPage) > 6) {
      if (pageActual <= 2) {
        for (i = 0; i < 3; i++) {
          buttons.push(
            <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i + 1}</button>
          )
        }
        buttons.push(
          <button>...</button>
        )
      } else if (pageActual >= Math.ceil(data.length / rowsPerPage) - 3) {
        buttons.push(
          <button>...</button>
        )
        for (i = Math.ceil(data.length / rowsPerPage) - 3; i < Math.ceil(data.length / rowsPerPage); i++) {
          buttons.push(
            <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i + 1}</button>
          )
        }
      } else {
        buttons.push(
          <button>...</button>
        )
        for (i = pageActual - 2; i < pageActual + 3; i++) {
          buttons.push(
            <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i + 1}</button>
          )
        }
        buttons.push(
          <button>...</button>
        )
      }
    } else {
      for (i = 0; i < Math.ceil(data.length / rowsPerPage); i++) {
        buttons.push(
          <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i + 1}</button>
        )
      }
    }
    buttons.push(
      <button className="pagina-siguiente-btn" onClick={() => {
        if (pageActual < data.length / rowsPerPage - 1) {
          setPageActual(pageActual + 1)
        }
      }}>Siguiente</button>
    )
    if (props.bordes) {
      buttons.push(
        <button className="pagina-fin-btn" onClick={() => {
          console.log(Math.ceil(data.length / rowsPerPage))
          setPageActual(Math.ceil(data.length / rowsPerPage) - 1)
        }}>Última</button>
      )
    }


    return <div className='contenedor-buttons'>{buttons}</div>
  }

  return (
    <>
      <div ref={tableRef} style={props.paginate ? { height: 'calc(100% - 50px)', overflow: 'hidden', position: 'relative' } : { height: '100%', overflow: 'auto', position: 'relative' }}>
        <Table stickyHeader className='table-dash'>
          <TableHead>
            <TableRow>
              {props.headers.map(header => {
                return (
                  <TableCell key={header.name} className='HeadCell' align={centrados.indexOf(props.headers.indexOf(header) + 1) >= 0 ? 'center' : 'left'} col={i++} onClick={e => {
                    let col = e.target.getAttribute('col');
                    if (!isNaN(parseInt(col))) {
                      if (orderBy.col === header.col) {
                        setOrderBy({
                          col: orderBy.col,
                          asc: !orderBy.asc
                        })
                      } else {
                        setOrderBy({
                          col: header.col,
                          asc: true
                        })
                      }
                    }
                  }}>
                    {header.name}
                    <div className='HeadArrows'>
                      <ArrowDropUpIcon onClick={e => {
                        setOrderBy({
                          col: header.col,
                          asc: true
                        })
                      }} style={orderBy.col === header.col && orderBy.asc ? { color: 'red' } : null} />
                      <ArrowDropDownIcon onClick={e => {
                        setOrderBy({
                          col: header.col,
                          asc: false
                        })
                      }} style={orderBy.col === header.col && !orderBy.asc ? { color: 'red' } : null} />
                    </div>
                  </TableCell>
                )
              }
              )}
            </TableRow>
          </TableHead>
          {
            props.loading ?
              null :
              <TableBody>
                {getRows()}
              </TableBody>
          }
        </Table>
        {
          props.loading ?
            <div style={{ textAlign: 'center', marginTop: '10px', position: 'absolute', width: '100%' }}>
              <CircularProgress size={50} />
            </div> :
            null
        }
        {
          data.length === 0 && !props.loading ?
            <p style={{ textAlign: 'center', width: '100%', position: 'absolute' }}>No hay registros que mostrar.</p> :
            null
        }
      </div>
      {
        props.paginate ?
          getPaginate() :
          null
      }
    </>
  )
}