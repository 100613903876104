import React from "react";

const DocentesIcon = props => (
  <svg viewBox="0 0 21.15 30" {...props}>
    <defs>
      <style>{".DocentesIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="DocentesIcon_svg__Layer_2" data-name="Layer 2">
      <g id="DocentesIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="DocentesIcon_svg__cls-1"
          d="M4.89 11.2C6 14 8 15.8 10.57 15.8s4.63-1.8 5.69-4.6c1.11-.1 1.7-1.18 1.91-2.45.26-1.52-.29-2.25-1.24-2.42C16.61 2.78 15.58 0 10.57 0s-6 2.78-6.36 6.34c-1 .17-1.5.9-1.24 2.42.22 1.24.83 2.35 1.92 2.44zm6.86-5.86a5 5 0 003.56 2.42c-.22 3.65-2.14 6.24-4.74 6.24-2.44 0-4.15-2.25-4.63-5.34a8.58 8.58 0 005.81-3.32z"
        />
        <path
          className="DocentesIcon_svg__cls-1"
          d="M15.82 14l-3.83 7-.37-1.32a1.63 1.63 0 00-1.07-2.87A1.6 1.6 0 009 18.39a1.63 1.63 0 00.57 1.23l-.39 1.32L5.33 14C2.87 15.45.35 18.8 0 25.6a2.1 2.1 0 001.8 2.3l2.08.1c1.26 1.43 3.87 2 6.74 2 3.05 0 5.45-.67 6.65-2l2.07-.11a2.1 2.1 0 001.8-2.3c-.34-6.75-2.88-10.12-5.32-11.59z"
        />
      </g>
    </g>
  </svg>
);

export default DocentesIcon;
