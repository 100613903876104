import React, { useState, useEffect, useContext } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import SearchInput from '../../Utils/Visuales/SearchInput'
import MasivaButton from '../../Utils/Botones/MasivaButton'
import DataTable from '../../Utils/DataTables'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import EditButton from '../../Utils/Botones/EditButton'
import moment from 'moment'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'
import Select from 'react-select'
import Modal from '../../Utils/Visuales/Modal'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'notistack'
import NivelesContext from '../../Utils/Providers/NivelesProvider'
import BlackButton from '../../Utils/Botones/BlackButton'
import { STYLES } from '../../Utils/variables'

export default function ReportesCriterios(props) {
  const headers = [
    { col: 0, name: 'Clave del Criterio' },
    { col: 1, name: 'Descripción del Criterio' },
    { col: 2, name: 'Cumplimiento' },
    { col: 3, name: 'Total de Grupos' },
    { col: 4, name: 'Acción' }
  ]
  const [dataFinal, setDataFinal] = useState([])
  const [dataReal, setDataReal] = useState([])
  const [loading, setLoading] = useState(false)
  const [year, setYear] = useState(new Date())
  const cycles = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' }
  ]
  const [cycle, setCycle] = useState({ value: '01', label: '01' })
  const [levels, setLevels] = useState(useContext(NivelesContext).state.levels)
  const [level, setLevel] = useState(levels[0])
  const [weeks, setWeeks] = useState([])
  const [week, setWeek] = useState({ value: 1, label: 1 })

  const [modalDetalle, setModalDetalle] = useState(false)
  const [modalDetalleLoading, setModalDetalleLoading] = useState(false)
  const [nombreCriterio, setNombreCriterio] = useState('')
  const modalHeaders = [
    { col: 0, name: 'Clave Curso' },
    { col: 1, name: 'Nombre Docente' },
    { col: 2, name: 'Clave Docente' },
    { col: 3, name: 'Status' }
  ]
  const [dataDetalle, setDataDetalle] = useState([])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (levels.length > 0) {
      setLevel(levels[0])
    } else {
      setDataReal([])
      setDataFinal([])
    }

    fetchData()
  }, [])

  useEffect(() => {
    let dataWeeks = []
    for (let i = 0; i < level.semanas; i++) {
      dataWeeks.push({
        value: i + 1,
        label: i + 1
      })
    }
    setWeeks(dataWeeks)
    setWeek(dataWeeks[0])
  }, [level])

  async function fetchData() {
    try {
      setLoading(true)
      let json
      if (week.value === 'todos') {
        json = {
          year: moment(year).format('YY'),
          ciclo: cycle.value,
          nivel: level.value,
        }
      } else {
        json = {
          year: moment(year).format('YY'),
          ciclo: cycle.value,
          nivel: level.value,
          semana: week.value
        }
      }

      let response = await postRequest('/reporte/criterio', json)
      if (response.s === 'OK') {
        let data = []
        if (response.d) {
          response.d.forEach(row => {
            data.push([
              row.clave,
              row.descripcion,
              row.porcentaje_alcance !== 'ND' && row.porcentaje_alcance !== 'inactivo' ? Math.round(parseFloat(row.porcentaje_alcance) * 10) / 10 + '%' : 'N/A',
              `${isNaN(row.porcentaje_alcance) ? '0' : Math.ceil((parseFloat(row.porcentaje_alcance) * parseFloat(row.total_cursos)) / 100)} de ${row.total_cursos}`,
              <EditButton onClick={() => { openModalDetalle(row.descripcion, row.clave) }}>Detalle</EditButton>
            ])
          })
        }
        setLoading(false)
        setDataFinal(data)
        setDataReal(data)
      } else {
        enqueueSnackbar(response.m, STYLES.failure)
      }
    } catch (error) {

    }
  }

  function search(text) {
    text = text.toLowerCase()
    let result = dataReal.filter(row => {
      if (row[0].toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      else if (row[2].toLowerCase().includes(text)) return true
      return false
    })
    setDataFinal(result)
  }

  function downloadReport() {
    let dataFinalDownload = [
      ['Año', moment(year).format('YYYY')],
      ['Ciclo', cycle.value],
      ['Nivel', level.value],
      ['SEMANA', week.value],
      ['Clave', 'Descripcion', 'Alcance']
    ]
    dataReal.forEach(row => {
      dataFinalDownload.push([
        row[0],
        row[1],
        row[2]
      ])
    })
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const ws = XLSX.utils.aoa_to_sheet(dataFinalDownload)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    let today = moment().format('YYYY/MM/DD')
    FileSaver.saveAs(data, `Reporte de Criterios ${today}.xlsx`)
  }

  async function openModalDetalle(name, clave) {
    setModalDetalle(true)
    setModalDetalleLoading(true)
    setNombreCriterio(name)

    let json = {
      ciclo: cycle.value,
      year: moment(year).format('YY'),
      nivel: level.value,
      semana: week.value,
      clave_criterio: clave
    }


    let response = await postRequest('/reporte/criterio/detalle', json)
    if (response.s === 'OK') {
      setModalDetalleLoading(false)

      if (response.d) {
        setDataDetalle(response.d.map(reporte => [
          reporte.clave_curso,
          reporte.nombre_docente,
          reporte.clave_docente,
          reporte.status_criterio === 'cumplio' ? 'cumplió' : 'no cumplió'
        ]))
      } else {
        setDataDetalle([])
      }

    } else {
      enqueueSnackbar(response.m, STYLES.failure)
    }
  }

  return (
    <Contenedor title='Reporte de Criterios'>
      <div className='opciones-bar opciones-bar-reporte'>
        <div className='tab-filtros' style={{ display: 'block' }}>
          <div>
            <MasivaButton onClick={downloadReport}>Descargar</MasivaButton>
          </div>
          <div>
            <p>Año:</p>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <DatePicker views={['year']} value={year} onChange={setYear} className='date-year' />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <p>Ciclo:</p>
            <Select
              options={cycles}
              className='select-weeks'
              classNamePrefix='select-search'
              value={cycle}
              onChange={setCycle}
            />
          </div>
          <div>
            <p>Nivel:</p>
            <Select
              options={levels}
              className='select-weeks'
              classNamePrefix='select-search'
              value={level}
              onChange={setLevel}
            />
          </div>
          <div>
            <p>Semana:</p>
            <Select
              options={weeks}
              className='select-docente'
              classNamePrefix='select-search'
              value={week}
              onChange={setWeek}
            />
          </div>
          <div>
            <BlackButton style={{ marginLeft: '20px' }} onClick={() => fetchData()}>Buscar</BlackButton>
          </div>
          <div style={{ float: 'right', paddingTop: '15px', marginLeft: 5, position: 'relative' }}>
            <SearchInput search={search} />
          </div>
        </div>
      </div>
      <div style={{ height: 'calc(100% - 120px)' }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[3]}
        />
      </div>
      <Modal open={modalDetalle} handleClose={() => setModalDetalle(false)} loading={modalDetalleLoading} title='Detalle Criterios' height={430}>
        <div className='nuevoUsuarioForm'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label>Nombre del criterio:</label>
              <input value={nombreCriterio} readOnly />
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: 300 }}>
                <DataTable
                  headers={modalHeaders}
                  data={dataDetalle}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Contenedor>
  )
}