import React from "react";

const CriteriosIcon = props => (
  <svg viewBox="0 0 30 30" {...props}>
    <defs>
      <style>{".CriteriosIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="CriteriosIcon_svg__Layer_2" data-name="Layer 2">
      <g id="CriteriosIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="CriteriosIcon_svg__cls-1"
          d="M15 0a15 15 0 1015 15A15 15 0 0015 0zm0 28a13 13 0 1113-13 13 13 0 01-13 13z"
        />
        <path
          className="CriteriosIcon_svg__cls-1"
          d="M22.78 5.12l-11.43 5.5a1.57 1.57 0 00-.73.73l-5.5 11.43a1.57 1.57 0 002.09 2.09l11.43-5.5a1.57 1.57 0 00.73-.73l5.5-11.43a1.57 1.57 0 00-2.09-2.09zm-6 11.67l-6.9 3.32 3.32-6.9 6.9-3.32z"
        />
      </g>
    </g>
  </svg>
);

export default CriteriosIcon;
