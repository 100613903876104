import React from 'react';
import PageTitle from '../PageTitle';
import PageTitleButton from '../PageTitleButton';
import './index.css';
import Footer from '../Footer'

export default function Contenedor(props){
    return (
        <main>
            {
                props.action?
                <PageTitleButton 
                    title={props.title} 
                    action={props.action} 
                    FiltrosComparadores={props.FiltrosComparadores}
                    clearAll = {props.clearAll}
                    />
                : 
                <PageTitle title={props.title} />
                
            }
            <div className='contenedor'>
                {props.children}
            </div>
            <Footer version='0.0.1' />
        </main>
    )
}