import React, { useState,useEffect } from 'react';
import './style.css';
import {ArrowDropDown} from '@material-ui/icons/';
export default function MultiSelect({
  options,
  selecteds,
  cantidad,
  onSet,
  setCantidad,
  seleccionPrevia
}) {
  const [opciones,setOpciones] = useState(options)
  const [seleccionados,setSeleccionados] = useState([]) 
  const [openOptions,setOpenOptions] = useState(false)
  const [previa,setPrevia] = useState(seleccionPrevia != undefined)

  useEffect(()=>{
    setSeleccionados([])
    if(selecteds.length>0){
      setSeleccionados(selecteds.split(','))
    }else{
      if(cantidad>0){
        options.forEach(option=>{
          selectOption(true,0)
        })
      }
    }
    setOpciones(options)
  },[options])

  function setValues(){
    let stringValues=""
    let stringCompleto=false
    if(previa){
      if(seleccionPrevia > 0){
        stringCompleto=true
      }
    }
    for(let i=0;i < seleccionados.length;i++){
      if(seleccionados[i]!="0"){
        stringValues=stringValues+seleccionados[i]
        if(i != seleccionados.length-1){
          stringValues=stringValues+','
        }
      }
    }
    onSet(stringValues)
    setCantidad(seleccionados.length === options.length ? options.length-1 : seleccionados.length)
  }

  function selectOption(e,value){
  	if(value==0){
  		let mySeleccionados=[]
  		if(e){
  			opciones.forEach(option=>{
          mySeleccionados.push(option.value)
  			})
  		}
  		setSeleccionados(mySeleccionados)
  	}else{	
	  	let mySeleccionados=[]
	  	if(e){
		  	seleccionados.forEach(selected=>{
		  		mySeleccionados.push(selected)
		  	})
		  	mySeleccionados.push(value)
		  	if(mySeleccionados.length+1===opciones.length){
		  		mySeleccionados.unshift("0")
		  	}
	  	}else{
	  		seleccionados.forEach(selected=>{
	  			if(selected != value && selected != '0'){
		  			mySeleccionados.push(selected)
	  			}
		  	})
		  	if(mySeleccionados.length+1===opciones.length){
		  		mySeleccionados.unshift("0")
		  	}
	  	}
		  setSeleccionados(mySeleccionados)
  	}
  }

  function searchOption(e){
  	let text=e.target.value
  	let myOptions=[]
  	options.forEach(option=>{
  		if(option.label!="Todos" && option.label.toString().toLowerCase().includes(text.toString().toLowerCase())){
  			myOptions.push(option)
  		}else if(option.label=="Todos" && text==""){
  			myOptions.push(option)
  		}
  	})
  	setOpciones(myOptions)
  }

  return (
    <div className="contenedor-multiselect">
      <input
      	value={"Total:" + String(seleccionados.includes("0") ? seleccionados.length-1 : seleccionados.length)}
      	onFocus={
          ()=>{
            setOpenOptions(true);
          }
        }
        type='text'
        name=''
        id=''
        className="principal-input-multiselect"
        placeholder='Seleccione...'
      />
      <span className="arrow-down-select">
        <ArrowDropDown/>
      </span>
      <div style={!openOptions ? {display:'none'}:{}} className="contenedor-aceptar-opciones">
        <input type="text" placeholder="Busca..." onChange={searchOption} />
      	<div className="div-contenedor-opciones">
      	  {
      	  	opciones.map(option=>(
      	  		<p title = {option.label} onClick={(e)=>{
                if(seleccionados.includes(option.value)){
                  selectOption(false,option.value)
                }else{
                  selectOption(true,option.value)
                }
              }}>{option.label.slice(0,85)} {option.label.length > 85 && '...'} 
                <input 
                    checked={seleccionados.includes(option.value)} 
                    onChange={(e)=>{selectOption(e.target.checked,option.value)}} type="checkbox"/>
              </p>
      	  	))
      	  }
      	</div>
  		  <button className="button-aceptar-opciones" onClick={()=>{setValues();setOpenOptions(false)}}>Aceptar</button>
	  </div>
    </div>
  );
}
