import React from "react";

const ReportesIcon = props => (
  <svg viewBox="0 0 47.5 25" {...props}>
    <defs>
      <style>{".ReportesIcon_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g id="ReportesIcon_svg__Layer_2" data-name="Layer 2">
      <g id="ReportesIcon_svg__Capa_1" data-name="Capa 1">
        <path
          className="ReportesIcon_svg__cls-1"
          d="M10.58 2.77v19.89l12.76-9.42L10.58 2.77zM32.52 15.06l-3.48 2.86-3.49-2.86L12.1 25h33.88l-13.46-9.94zM34.74 13.24l12.76 9.42V2.77L34.74 13.24zM0 16.73h7.95v2.78H0zM1.86 11.2h6.09v2.78H1.86zM3.71 5.66h4.24v2.78H3.71zM11.59 0l17.45 14.32L46.48 0H11.59z"
        />
      </g>
    </g>
  </svg>
);

export default ReportesIcon;
