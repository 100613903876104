import React from "react";

function EditAvatar(props) {
  return (
    <svg viewBox="0 0 77.7 77.69" {...props}>
      <defs>
        <style>
          {
            ".EditAvatar_svg__cls-1{fill:#fff}.EditAvatar_svg__cls-2{fill:#1b1c20}"
          }
        </style>
      </defs>
      <g id="EditAvatar_svg__Layer_2" data-name="Layer 2">
        <g id="EditAvatar_svg__Capa_1" data-name="Capa 1">
          <path
            className="EditAvatar_svg__cls-1"
            d="M38.84 0A38.85 38.85 0 110 38.85 38.84 38.84 0 0138.84 0z"
          />
          <ellipse
            className="EditAvatar_svg__cls-2"
            cx={38.85}
            cy={38.85}
            rx={32.95}
            ry={33.97}
          />
          <ellipse
            className="EditAvatar_svg__cls-2"
            cx={38.85}
            cy={38.85}
            rx={30.35}
            ry={31.29}
          />
          <path
            className="EditAvatar_svg__cls-1"
            transform="rotate(-45 37.47 39.93)"
            d="M23.28 34.32h28.37v11.21H23.28z"
          />
          <path
            className="EditAvatar_svg__cls-1"
            d="M57.48 24l-3.53-3.53a3.51 3.51 0 00-5 0l-3.35 3.4 7.92 7.93 4-4a2.7 2.7 0 00-.04-3.8zM19.33 57.13a.9.9 0 001.09 1.07l8.83-2.14-7.92-7.93z"
          />
        </g>
      </g>
    </svg>
  );
}

export default EditAvatar;
