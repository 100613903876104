const production = process.env.NODE_ENV === 'production'
const hostname = window.location.hostname.split('.')[0]
console.log('first hostname', hostname)
let host = ''

const config = {
  ENV: production ? 'production' : 'development',
  isDev: !production,
  maintenance: false,
  host: host,
}

export default config