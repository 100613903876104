import React, { useState } from 'react';
import './index.css';
import {Link, useLocation} from 'react-router-dom';
import MenuIcon from '../../images/MenuIcon';
import CriteriosIcon from '../../images/CriteriosIcon';
import DashboardIcon from '../../images/DashboardIcon';
import DocentesIcon from '../../images/DocentesIcon';
import EnvioIcon from '../../images/EnvioIcon';
import HistorialIcon from '../../images/HistorialIcon';
import ReportesIcon from '../../images/ReportesIcon';
import UsuariosIcon from '../../images/UsuariosIcon';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EventIcon from '@material-ui/icons/Event';
import Can from '../Funciones/can'

export default function Menu(props){
    let {pathname} = useLocation();
    const [reportes, setReportes] = useState(false)
    
    return(
        <aside>
            <ul>
                <li className='menu-title'>
                    <MenuIcon />
                    Menú
                </li>
                <Can I='read' a='dashboard'>
                    <li className={pathname === '/dashboard' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/dashboard'>
                            <DashboardIcon /> Dashboard
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='usuarios'>
                    <li className={pathname === '/usuarios' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/usuarios'>
                            <UsuariosIcon style={{position: 'absolute', top: '8px', width: '18px'}} /> Usuarios
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='docentes'>
                    <li className={pathname === '/docentes' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/docentes'>
                            <DocentesIcon style={{position: 'absolute', top: '5px', width: '18px'}} /> Docentes
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='coordinadores'>
                    <li className={pathname === '/coordinadores' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)} >
                        <Link to='/coordinadores'>
                            <SupervisorAccountIcon style={{position: 'absolute', width: '26px', top: '8px'}} /> Coordinadores
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='calendario'>
                    <li className={pathname === '/calendario' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/calendario'>
                            <EventIcon style={{position: 'absolute', width: '26px', top: '8px'}} /> Calendario
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='criterios'>
                    <li className={pathname === '/criterios' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/criterios'>
                            <CriteriosIcon /> Criterios
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='reporte'>
                    <li className={'/' + pathname.split('/')[1] === '/reportes' ? 'active' : null} style={{position: 'relative'}}>
                        <a href="#" onClick={() => setReportes(!reportes)}>
                            <ReportesIcon style={{position: 'absolute', width: '30px', top: '13px', left: '10px'}} /> Reportes
                        </a>
                        {
                            reportes ?
                            <div className='reportes-tab'>
                                <Can I='read' a='reporte_listaverificacion'>
                                    <Link to='/reportes/lista' className={pathname === '/reportes/lista' ? 'active' : null}>Lista de Verificación</Link>
                                </Can>
                                <Can I='read' a='reporte_concentrado'>
                                    <Link to='/reportes/concentrado' className={pathname === '/reportes/concentrado' ? 'active' : null}>Concentrado</Link>
                                </Can>
                                <Can I='read' a='reporte_criterios'>
                                    <Link to='/reportes/criterios' className={pathname === '/reportes/criterios' ? 'active' : null}>Criterios</Link>
                                </Can>
                                <Can I='read' a='reporte_curso'>
                                    <Link to='/reportes/curso' className={pathname === '/reportes/curso' ? 'active' : null}>Grupos</Link>
                                </Can>
                            </div> : null
                        }
                    </li>
                </Can>
                <Can I='read' a='historial'>
                    <li className={pathname === '/historial' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/historial'>
                            <HistorialIcon style={{position: 'absolute', top: '9px', width: '18px'}} /> Historial
                        </Link>
                    </li>
                </Can>
                <Can I='read' a='envioautomatico'>
                    <li className={pathname === '/envio' ? 'active' : null} style={{position: 'relative'}} onClick={() => setReportes(false)}>
                        <Link to='/envio'>
                            <EnvioIcon style={{position: 'absolute', top: '9px'}} /> Envío Automático
                        </Link>
                    </li>
                </Can>
            </ul>
        </aside>
    )
}